import { Guid } from '../types/api';

export function generateGuid(): Guid {
  let result = '';
  for (let i = 0; i < 32; i++) {
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      result += '-';
    }
    result += Math.floor(Math.random() * 16)
      .toString(16)
      .toUpperCase();
  }
  return result;
}
