import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { StoreState } from '../redux/reducers';

type Props = {
  path: string;
  exact?: boolean;
  component: ReactNode;
};

export const PrivateRoute = ({ path, component, exact }: Props) => {
  const isLoggedIn = useSelector((state: StoreState) => state.account.isLoggedIn);

  if (isLoggedIn === false) return <Redirect to="/prihlaseni" />;

  return (
    <Route path={path} exact={exact}>
      {component}
    </Route>
  );
};
