import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { api } from '../../api';
import { Endpoints } from '../../api/endpoints';
import { Alert, ButtonLink } from '../../components/atoms';
import { InputField } from '../../components/molecules';
import { CustomerResponse } from '../../types/api/customers';
import { LoadingSkeleton } from './components';
import { CustomersTableHead } from './components/CustomersTableHead';

export const CustomersPage = () => {
  const [filter, setFilter] = useState('');
  const query = useQuery('customers', () => api().get<CustomerResponse[]>(Endpoints.Customers.Index));

  const filteredCustomers = [...(query.data?.data ?? [])].filter(
    (customer) =>
      filter === '' ||
      customer.email.normalize().toLowerCase().includes(filter.normalize().toLowerCase()) ||
      customer.firstnameSurname.normalize().toLowerCase().includes(filter.normalize().toLowerCase()) ||
      customer.phone.normalize().toLowerCase().includes(filter.normalize().toLowerCase()),
  );

  return (
    <>
      <main className="p-6">
        <header className="mb-4 flex justify-between">
          <div>
            <h1 className="mb-2 text-3xl font-semibold tracking-wide">Zákazníci</h1>
            <p className="text-sm text-gray-500">Přehled všech zákazníků </p>
          </div>
          <InputField
            type="search"
            icon="search"
            label="Hledat"
            register={{ name: 'search', value: filter, onChange: (event) => setFilter(event.currentTarget.value) }}
            error={undefined}
          />
        </header>
        <section className="pb-16">
          {query.isLoading ? (
            <LoadingSkeleton />
          ) : query.isError ? (
            <Alert variant="error">{(query.error as AxiosError)?.response?.data ?? 'Nepodařilo se načíst data!'}</Alert>
          ) : (
            <div className="overflow-hidden rounded-md border border-slate-200 bg-white shadow">
              <table className="w-full">
                <CustomersTableHead />
                <tbody>
                  {filteredCustomers.map((customer) => (
                    <tr key={customer.id} className="border-b border-slate-100">
                      <td className="px-4 py-3 text-sm font-semibold text-slate-800">
                        <div className="flex items-center gap-6">
                          <span className="flex h-12 w-12 items-center justify-center rounded bg-slate-200 text-lg text-slate-500 shadow-sm">
                            <FontAwesomeIcon icon="user" />
                          </span>
                          <span>{customer.firstnameSurname}</span>
                        </div>
                      </td>
                      <td className="px-4 py-3 text-sm text-slate-800">{customer.phone}</td>
                      <td className="px-4 py-3 text-sm text-slate-800">{customer.email}</td>
                      <td className="px-4 py-3 text-sm text-slate-800">{customer.panoramaCount}</td>
                      <td className="w-px px-4 py-3 text-sm text-slate-800">
                        <div className="flex justify-end gap-4">
                          <ButtonLink to={`/?zakaznik=${customer.id}`} variant="gray" size="small">
                            Zobrazit panorama
                          </ButtonLink>
                          <ButtonLink
                            to={`/zakaznici/upravit/${customer.id}`}
                            variant="blue"
                            icon="pencil-alt"
                            size="small"
                          >
                            Upravit
                          </ButtonLink>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </section>
        <section className="fixed bottom-3 right-6 z-20 shadow-lg shadow-blue-500/20">
          <ButtonLink variant="blue" icon="user-plus" to="/zakaznici/novy">
            Vytvořit nového zákazníka
          </ButtonLink>
        </section>
      </main>
    </>
  );
};
