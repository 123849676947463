import { Skeleton } from '../../../components/atoms/Skeleton';

export const LoadingSkeleton = () => {
  return (
    <>
      <section className="mx-auto mb-4 max-w-xl">
        <div className="space-y-1">
          <Skeleton height={20} width={72} />
          <Skeleton height={38} />
          <Skeleton height={13} width={136} />
        </div>
      </section>

      <Skeleton height={1} className="mx-auto my-8 max-w-2xl" />

      <section className="mx-auto max-w-xl">
        <div className="space-y-2">
          <Skeleton height={32} width={156} />
          <Skeleton height={20} width={136} />
        </div>
        <div className="mt-4 space-y-4">
          <div className="space-y-1">
            <Skeleton height={20} width={72} />
            <Skeleton height={38} />
          </div>
          <div className="space-y-1">
            <Skeleton height={20} width={72} />
            <Skeleton height={38} />
          </div>
          <Skeleton className="ml-auto" width={100} height={38} />
        </div>
      </section>

      <Skeleton height={1} className="mx-auto my-8 max-w-2xl" />

      <section className="mx-auto max-w-xl">
        <div className="space-y-2">
          <Skeleton height={32} width={156} />
          <Skeleton height={20} width={136} />
        </div>
        <div className="mt-4 space-y-4">
          <div className="space-y-1">
            <Skeleton height={20} width={72} />
            <Skeleton height={38} />
          </div>
          <div className="space-y-1">
            <Skeleton height={20} width={72} />
            <Skeleton height={38} />
          </div>
          <div className="space-y-1">
            <Skeleton height={20} width={72} />
            <Skeleton height={38} />
          </div>
          <Skeleton className="ml-auto" width={100} height={38} />
        </div>
      </section>
    </>
  );
};
