import { takeEvery } from 'redux-saga/effects';
import { LoginSuccessAction } from '../actions/account';

export function* putTokenToLocalStorageSaga(action: LoginSuccessAction) {
  const token = action.payload.token;

  localStorage.setItem('token', token);
  yield;
}
export function* removeTokenFromLocalStorage() {
  localStorage.removeItem('token');
  yield;
}

export function* watchPutTokenToLocalStorageSaga() {
  yield takeEvery('ACCOUNT.LOGIN.SUCCESS', putTokenToLocalStorageSaga);
}

export function* watchRemoveTokenFromLocalStorageSaga() {
  yield takeEvery('ACCOUNT.LOGOUT', removeTokenFromLocalStorage);
}

const accountSagas = [watchPutTokenToLocalStorageSaga(), watchRemoveTokenFromLocalStorageSaga()];

export default accountSagas;
