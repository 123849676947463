import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InfoHotspotModal, MainHotspotModal } from '../../components/organisms';

import { generateGuid } from '../../functions/guid';
import {
  cancelMoveHotspot,
  confirmMoveHotspot,
  fetch,
  moveHotspot,
  savePanorama,
} from '../../redux/actions/panorama.edit';
import {
  cancelInfoHotspotPlace,
  closeInfoHotspotModal,
  confirmInfoHotspotModal,
  createInfoHotspot,
  deleteInfoHotspot,
  openInfoHotspotModal,
  updateInfoHotspot,
} from '../../redux/actions/panorama.edit.infoHotspot';
import {
  closeMainHotspotModal,
  confirmMainHotspotModal,
  createMainHotspot,
  deleteMainHotspot,
  openMainHotspotModal,
  updateMainHotspot,
} from '../../redux/actions/panorama.edit.mainhotspot';
import { StoreState } from '../../redux/reducers';
import { GalleryImageState, PanoramaEditState, UntouchedGalleryImage } from '../../redux/reducers/panorama.edit';
import { PanoramaCanvas, PanoramaLoading, PanoramaLoadingError, PanoramaSidebar } from './Components';

type UrlParams = {
  id: string;
};

export const EditPanoramaPage = () => {
  const dispatch = useDispatch();

  const id = useParams<UrlParams>().id;
  const state = useSelector<StoreState, PanoramaEditState>((state) => state.panoramaEdit);

  useEffect(() => {
    dispatch(fetch(id));
  }, [id, dispatch]);

  const confirmInfoHotspotPlace = (yaw: number, pitch: number) => {
    dispatch(
      createInfoHotspot(
        state.infoHotspotModal.title,
        yaw,
        pitch,
        state.infoHotspotModal.description,
        state.infoHotspotModal.thumbnail,
        state.infoHotspotModal.link,
      ),
    );
  };

  const confirmMainHotspotPlace = (yaw: number, pitch: number) => {
    dispatch(
      createMainHotspot(
        state.mainHotspotModal.title,
        yaw,
        pitch,
        state.mainHotspotModal.description,
        state.mainHotspotModal.matterportLink,
        state.mainHotspotModal.youtubeLink,
        state.mainHotspotModal.links,
        state.mainHotspotModal.thumbnail,
        state.mainHotspotModal.galleryImages,
      ),
    );
  };

  return (
    <>
      <main className="flex h-full items-stretch">
        {state.query.state === 'Loading' ? (
          <PanoramaLoading />
        ) : state.query.state === 'Error' ? (
          <PanoramaLoadingError />
        ) : (
          <>
            <PanoramaSidebar
              onSaveMainInfo={(enabled, yaw, pitch, expireDate) =>
                dispatch(savePanorama(enabled, yaw, pitch, expireDate))
              }
              onAddInfoHotspot={() => dispatch(openInfoHotspotModal())}
              onMoveInfoHotspot={(hotspot) => dispatch(moveHotspot(hotspot.id, false))}
              onEditInfoHotspot={(hotspot) => dispatch(openInfoHotspotModal(hotspot))}
              onDeleteInfoHotspot={(hotspot) => dispatch(deleteInfoHotspot(hotspot.id))}
              onAddMainHotspot={() => dispatch(openMainHotspotModal(undefined))}
              onMoveMainHotspot={() => dispatch(moveHotspot(state.mainHotspot!.id, true))}
              onEditMainHotspot={() =>
                dispatch(
                  openMainHotspotModal(
                    state.mainHotspot
                      ? {
                          ...state.mainHotspot,
                        }
                      : undefined,
                  ),
                )
              }
              onDeleteMainHotspot={() => dispatch(deleteMainHotspot())}
              title={state.title}
              customerName={state.customerName}
              expireDate={new Date(state.expireDate)}
              publishDate={new Date(state.publishDate)}
              enabled={state.enabled}
              initialYaw={state.initialYaw}
              initialPitch={state.initialPitch}
              infoHotspots={state.infoHotspots}
              mainHotspot={state.mainHotspot}
              isPlacingHotspot={state.isPlacingInfoHotspot || state.movingHotspot !== undefined}
              viewer={state.viewer}
              scene={state.scene}
            />
            <PanoramaCanvas
              onCancelHotspotPlace={() =>
                state.movingHotspot ? dispatch(cancelMoveHotspot()) : dispatch(cancelInfoHotspotPlace())
              }
              onConfirmHotspotPlace={(yaw, pitch) => {
                state.movingHotspot
                  ? dispatch(confirmMoveHotspot(yaw, pitch))
                  : state.isPlacingInfoHotspot
                  ? confirmInfoHotspotPlace(yaw, pitch)
                  : confirmMainHotspotPlace(yaw, pitch);
              }}
              isPlacingHotspot={state.isPlacingInfoHotspot || state.isPlacingMainHotspot || !!state.movingHotspot}
              viewer={state.viewer}
            />
          </>
        )}
      </main>
      <InfoHotspotModal
        isOpen={state.infoHotspotModal.isOpen}
        hotspot={{ ...state.infoHotspotModal }}
        onClose={() => dispatch(closeInfoHotspotModal())}
        onConfirm={(data) => {
          if (state.infoHotspotModal.isNew) {
            dispatch(
              confirmInfoHotspotModal(
                generateGuid(),
                data.title,
                data.description,
                data.thumbnailEdit,
                data.thumbnail,
                data.link,
              ),
            );
          } else {
            dispatch(
              updateInfoHotspot(data.id!, data.title, data.description, data.thumbnailEdit, data.thumbnail, data.link),
            );
          }
        }}
      />
      <MainHotspotModal
        isOpen={state.mainHotspotModal.isOpen}
        hotspot={{
          ...state.mainHotspotModal,
          galleryImages: state.mainHotspotModal.galleryImages.filter(
            (image: GalleryImageState): image is UntouchedGalleryImage => image.state === 'Untouched',
          ),
        }}
        onClose={() => dispatch(closeMainHotspotModal())}
        onConfirm={(data) => {
          if (state.mainHotspotModal.isNew) {
            dispatch(
              confirmMainHotspotModal(
                data.title,
                data.description,
                data.youtubeLink,
                data.matterportLink,
                data.links,
                data.thumbnailEdit,
                data.thumbnail,
                data.galleryImages,
              ),
            );
          } else {
            dispatch(
              updateMainHotspot(
                data.title,
                data.description,
                data.youtubeLink,
                data.matterportLink,
                data.links,
                data.thumbnailEdit,
                data.thumbnail,
                data.galleryImages,
              ),
            );
          }
        }}
        isConfirming={state.mainHotspotModal.mutation.state === 'Loading'}
      />
    </>
  );
};
