type Props = {
  id: string;
  value: string;
  onChange: (value: string) => void;
};

export const ColorInput = ({ id, value, onChange }: Props) => {
  return (
    <div className="flex items-stretch overflow-hidden rounded-md border border-slate-300 bg-white shadow-sm transition focus-within:border-blue-500">
      <input
        type="text"
        id={id}
        name={id}
        className="flex-1 px-4  py-2 focus:outline-none"
        value={value}
        onInput={(e) => onChange(e.currentTarget.value)}
      />
      <div className="py-1 pl-4 pr-2">
        <div className="h-full w-16 rounded-md border shadow-sm" style={{ backgroundColor: value }}>
          <input
            type="color"
            id={id}
            name={id}
            className="h-full w-full opacity-0"
            value={value}
            onInput={(e) => {
              console.log(e.currentTarget.value);
              onChange(e.currentTarget.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};
