import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import { FormEvent, Fragment, useState } from 'react';
import { Alert, Button, Input } from '../../../components/atoms';
import { InfoHotspot, MainHotspot } from '../../../types';
import { Scene, Viewer } from '../../../types/marzipano-types';

type Props = {
  onSaveMainInfo: (enabled: boolean, initialYaw: number, initialPitch: number, expireDate: Date) => void;
  onAddInfoHotspot: () => void;
  onMoveInfoHotspot: (hotspot: InfoHotspot) => void;
  onEditInfoHotspot: (hotspot: InfoHotspot) => void;
  onDeleteInfoHotspot: (hotspot: InfoHotspot) => void;
  onMoveMainHotspot: () => void;
  onAddMainHotspot: () => void;
  onEditMainHotspot: () => void;
  onDeleteMainHotspot: () => void;
  title: string;
  customerName: string;
  publishDate: Date;
  expireDate: Date;
  enabled: boolean;
  initialYaw: number;
  initialPitch: number;
  infoHotspots: InfoHotspot[];
  mainHotspot?: MainHotspot;
  isPlacingHotspot: boolean;
  viewer: Viewer;
  scene: Scene;
};

export const PanoramaSidebar = ({
  onSaveMainInfo,
  onAddInfoHotspot,
  onMoveInfoHotspot,
  onEditInfoHotspot,
  onDeleteInfoHotspot,
  onAddMainHotspot,
  onMoveMainHotspot,
  onEditMainHotspot,
  onDeleteMainHotspot,
  title,
  customerName,
  publishDate,
  expireDate,
  enabled,
  initialYaw,
  initialPitch,
  infoHotspots,
  mainHotspot,
  isPlacingHotspot,
  viewer,
  scene,
}: Props) => {
  const [isEnabled, setIsEnabled] = useState(enabled);
  const [initialYawState, setInitialYawState] = useState<number>(initialYaw);
  const [initialPitchYawState, setInitialPitchState] = useState<number>(initialPitch);
  const [expireDateState, setExpireDateState] = useState<Date>(expireDate);

  const setInitialView = () => {
    const yaw = viewer.view().yaw();
    const pitch = viewer.view().pitch();
    setInitialYawState(yaw);
    setInitialPitchState(pitch);
  };

  const showInitialView = () => {
    scene.lookTo(
      {
        yaw: initialYawState,
        pitch: initialPitchYawState,
      },
      {
        transitionDuration: 500,
      },
    );
  };

  const lookTo = (yaw: number, pitch: number) => {
    scene.lookTo(
      {
        yaw: yaw,
        pitch: pitch,
      },
      {
        transitionDuration: 500,
      },
    );
  };

  const onSubmitMainInfo = (e: FormEvent) => {
    e.preventDefault();
    onSaveMainInfo(isEnabled, initialYawState, initialPitchYawState, expireDateState);
  };

  return (
    <Transition
      show={isPlacingHotspot === false}
      appear
      enter="transition-all duration-700"
      enterFrom="-translate-x-full opacity-0"
      enterTo="opacity-100"
      leave="transition-all duration-700"
      leaveFrom="opacity-100"
      leaveTo="-translate-x-full  opacity-0"
      as={Fragment}
    >
      <section className="absolute bottom-2 top-[44px] z-[540] mt-2 ml-2 flex min-w-[460px] flex-col space-y-2">
        {/* Basic info */}
        <form
          action=""
          onSubmit={onSubmitMainInfo}
          className="max-w-lg overflow-clip overflow-y-visible rounded-lg border-b bg-white px-6 pt-6 pb-4 shadow-xl"
        >
          <h1 className="pb-2 text-xl font-semibold tracking-wide">Upravit panorama </h1>
          <dl className="pb-2 text-sm text-slate-700">
            <div className="mb-1 flex justify-between gap-4 pb-1">
              <dt>Název</dt>
              <dd>{title}</dd>
            </div>
            <div className="mb-1 flex justify-between gap-4 pb-1">
              <dt>Zákazník</dt>
              <dd>{customerName}</dd>
            </div>
            <div className="mb-1 flex justify-between gap-4 pb-1">
              <dt>Datum vytvoření</dt>
              <dd>{new Date(publishDate).toLocaleDateString('cs')}</dd>
            </div>
          </dl>
          <div className="border-t border-gray-200 pt-2 pb-4">
            <div className="group relative -mx-3 flex items-center rounded-md py-2 px-2.5">
              <label
                htmlFor="expireDate"
                className="block flex-1 text-sm font-medium text-slate-700 transition duration-300 group-hover:text-slate-900"
              >
                Datum expirace
              </label>
              <div className="w-[168px]">
                <Input
                  name="expireDate"
                  id="expireDate"
                  type="date"
                  size="tiny"
                  value={new Date(expireDateState).toISOString().substr(0, 10)}
                  onChange={(value) => setExpireDateState(value ? new Date(value) : new Date())}
                />
              </div>
            </div>
            <div className="group relative -mx-3 flex items-center rounded-md py-2 px-2.5">
              <p className="block flex-1 text-sm font-medium text-slate-700 transition duration-300 group-hover:text-slate-900">
                Úvodní pohled <br />
                <span className="flex text-xs font-light text-slate-500 transition duration-300 group-hover:text-slate-600">
                  {initialYawState.toFixed(6)}, {initialPitchYawState.toFixed(6)}
                </span>
              </p>
              <div className="ml-3 flex items-center gap-2">
                <Button variant="gray" size="tiny" onClick={showInitialView}>
                  Zobrazit
                </Button>
                <Button variant="gray" size="tiny" onClick={setInitialView}>
                  Nastavit
                </Button>
              </div>
            </div>
            <div className="group relative -mx-3 flex items-center rounded-md py-2 px-3 transition duration-300 hover:bg-slate-100">
              <label htmlFor="enabled" className="min-w-0 flex-1 text-sm">
                <span className="block font-medium text-slate-700 transition duration-300 group-hover:text-slate-900">
                  Zapnuto
                </span>
                <span className="block text-xs font-light text-slate-500 transition duration-300 group-hover:text-slate-600">
                  Zapnutá panoramata jsou veřejně dostupná
                </span>
              </label>
              <div className="ml-3 flex h-5 items-center">
                <input
                  id="enabled"
                  name="enabled"
                  type="checkbox"
                  checked={isEnabled}
                  onChange={() => setIsEnabled(!isEnabled)}
                  className="h-4 w-4 rounded border-slate-300 accent-blue-600"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <Button variant="blue" size="small" icon="check" type="submit">
              Uložit informace
            </Button>
          </div>
        </form>

        {/* Main hotspot */}
        <div className="overflow-hidden rounded-lg border-b bg-white px-6 pt-6 pb-4 shadow-xl">
          <h2 className="mb-4 text-lg font-semibold tracking-wide">Hlavní hotspot </h2>
          <div className="-mx-4 flex items-center gap-1.5 rounded-md px-4 py-2 text-slate-700">
            <h3 className="flex-grow truncate font-medium ">
              {mainHotspot?.title ?? <span className="font-normal  text-slate-500">Zatím nevytvořen</span>}
            </h3>
            {mainHotspot === undefined ? (
              <Button variant="blue" size="small" icon="plus" onClick={onAddMainHotspot}>
                <span className="text-xs">Vytvořit</span>
              </Button>
            ) : (
              <>
                <Button
                  variant="transparent"
                  title={`Zobrazit hlavní hotspot ${mainHotspot.title}`}
                  size="smallSquare"
                  onClick={() => lookTo(mainHotspot.yaw, mainHotspot.pitch)}
                >
                  <FontAwesomeIcon icon="eye" className="text-xs" />
                </Button>
                <Button
                  variant="gray"
                  title={`Přesunout hlavní hotspot ${mainHotspot.title}`}
                  size="smallSquare"
                  onClick={() => onMoveMainHotspot()}
                >
                  <FontAwesomeIcon icon="arrows-alt" className="text-xs" />
                </Button>
                <Button
                  variant="red"
                  title={`Odstranit hlavní hotspot ${mainHotspot.title}`}
                  size="smallSquare"
                  onClick={() => onDeleteMainHotspot()}
                >
                  <FontAwesomeIcon icon="trash" className="text-xs" />
                </Button>
                <Button
                  variant="blue"
                  title={`Upravit hlavní hotspot ${mainHotspot.title}`}
                  size="smallSquare"
                  onClick={() => onEditMainHotspot()}
                >
                  <FontAwesomeIcon icon="pencil-alt" className="text-xs" />
                </Button>
              </>
            )}
          </div>
        </div>

        {/* Info Hotspots */}
        <div className="flex flex-col overflow-hidden rounded-lg bg-white px-3 pt-6 pb-4 shadow-xl">
          <h2 className="mb-4 px-3 text-lg font-semibold tracking-wide">Hotspoty </h2>
          <div className="scrollGradient mb-4 max-h-[78%] overflow-y-auto rounded-md">
            {infoHotspots.length === 0 && <Alert variant="info">Nenalezen žádný hotspot</Alert>}
            {infoHotspots.map((hotspot) => (
              <div
                key={hotspot.id}
                className="flex items-center gap-1.5 rounded-md px-3 py-2 text-slate-700 transition duration-300 hover:bg-slate-100 hover:text-slate-900 hover:shadow-sm"
              >
                <h3 className="flex-grow truncate">{hotspot.title}</h3>

                <Button
                  variant="transparent"
                  title={`Zobrazit hotspot ${hotspot.title}`}
                  size="smallSquare"
                  onClick={() => lookTo(hotspot.yaw, hotspot.pitch)}
                >
                  <FontAwesomeIcon icon="eye" className="text-xs" />
                </Button>
                <Button
                  variant="gray"
                  title={`Přesunout hotspot ${hotspot.title}`}
                  size="smallSquare"
                  onClick={() => onMoveInfoHotspot(hotspot)}
                >
                  <FontAwesomeIcon icon="arrows-alt" className="text-xs" />
                </Button>
                <Button
                  variant="red"
                  title={`Odstranit hotspot ${hotspot.title}`}
                  size="smallSquare"
                  onClick={() => onDeleteInfoHotspot(hotspot)}
                >
                  <FontAwesomeIcon icon="trash" className="text-xs" />
                </Button>
                <Button
                  variant="blue"
                  title={`Upravit hotspot ${hotspot.title}`}
                  size="smallSquare"
                  onClick={() => onEditInfoHotspot(hotspot)}
                >
                  <FontAwesomeIcon icon="pencil-alt" className="text-xs" />
                </Button>
              </div>
            ))}
          </div>

          <div className="flex items-stretch justify-end gap-3 px-3">
            <Button variant="blue" size="small" icon="plus" onClick={() => onAddInfoHotspot()}>
              Vytvořit hotspot
            </Button>
          </div>
        </div>
      </section>
    </Transition>
  );
};
