import { classNames } from '../../utils';

type Props = {
  height?: number;
  width?: number;
  className?: string;
};

export const Skeleton = ({ height, width, className }: Props) => {
  return (
    <div
      aria-live="polite"
      aria-busy="true"
      style={{ height: height ?? 24, width: width }}
      className={classNames('animate-pulse rounded bg-slate-200', className)}
    ></div>
  );
};
