export function resize(fileBase64: string, width: number, height: number) {
  return new Promise<string>((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      var canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');

      // set its dimension to target size
      canvas.width = width;
      canvas.height = height;

      // draw source image into the off-screen canvas:
      ctx!.drawImage(img, 0, 0, width, height);

      resolve(canvas.toDataURL('image/webp'));
    };
    img.onerror = reject;

    const blob = b64toBlob(fileBase64, 'jpg');
    const blobUrl = URL.createObjectURL(blob);
    img.src = blobUrl;
  });
}

export function resizeToBlob(fileBase64: string, width: number, height: number) {
  return new Promise<Blob>((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      var canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');

      // set its dimension to target size
      canvas.width = width;
      canvas.height = height;

      // draw source image into the off-screen canvas:
      ctx!.drawImage(img, 0, 0, width, height);
      canvas.toBlob((blob) => resolve(blob as Blob));
    };
    img.onerror = reject;

    const blob = b64toBlob(fileBase64, 'jpg');
    const blobUrl = URL.createObjectURL(blob);
    img.src = blobUrl;
  });
}

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
