import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  body: ReactNode;
  footer: ReactNode;
};

export const Modal = ({ isOpen, onClose, title, body, footer }: Props) => (
  <Transition show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-[1001]" onClose={onClose}>
      <div className="min-h-screen px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black/75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="block py-6 px-6 text-xl font-semibold text-gray-900">
                  {title}
                </Dialog.Title>
                <main className="px-6">{body}</main>
                <footer className="flex justify-end gap-3 py-4 px-6">{footer}</footer>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Dialog>
  </Transition>
);
