import { AxiosError } from 'axios';
import { FormEvent, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { Button, Input, Label, Logo } from '../components/atoms';
import { ConfirmAccountRequest } from '../types/api/account';

type Params = {
  id: string;
};

export const ConfirmAccountPage = () => {
  const history = useHistory();
  const { id } = useParams<Params>();

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const mutation = useMutation(
    (request: ConfirmAccountRequest) => api().post(Endpoints.Account.Confirm, request),
    {
      onSuccess: (response) => {
        toast('Váš účet byl potvrzen. Nyní se můžete přihlásit', { type: 'success' });
        history.push('/');
      },
      onError: (error: AxiosError) => {
        toast(error.response?.data, { type: 'error' });
      },
    },
  );

  function onSubmit(event: FormEvent) {
    event.preventDefault();
    mutation.mutate({ password, passwordRepeat, linkId: id });
  }

  return (
    <>
      <main className="flex h-full  bg-slate-900 py-36">
        <div className="container mx-auto px-4">
          <section className="mx-auto max-w-lg rounded-lg bg-white shadow-2xl">
            <div className="border-b px-8 pt-6 pb-4 shadow">
              <div className="text-slate-7 00 mb-4 flex items-center justify-center gap-4 text-xl font-semibold  uppercase">
                <Logo />
                <div>Prezentace Nemovitostí</div>
              </div>
              <h1 className="text-center text-4xl font-bold uppercase text-blue-500">Flypano</h1>
            </div>
            <form className="space-y-4 px-8 py-6" action="" onSubmit={onSubmit}>
              <h2 className="text-center text-2xl font-semibold text-gray-900">
                Vítejte ve flypano.cz
              </h2>
              <p className="text-center leading-relaxed text-slate-700">
                Pro potvrzení vašeho účtu vyplňte vaše heslo, poté budete přesměrování na přihlášení
                do aplikace
              </p>
              <div className="mb-4">
                <Label htmlFor="password">Heslo</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  icon="lock"
                  onChange={(value) => setPassword(value)}
                />
              </div>
              <div className="mb-4">
                <Label htmlFor="password">Zopakujte heslo</Label>
                <Input
                  type="password"
                  name="password-repeat"
                  id="password-repeat"
                  icon="lock"
                  value={passwordRepeat}
                  onChange={(value) => setPasswordRepeat(value)}
                />
              </div>
              <div className="flex flex-col items-stretch justify-end">
                <Button type="submit" variant="blue" icon="check" isLoading={mutation.isLoading}>
                  Potvrdit
                </Button>
              </div>
            </form>
          </section>
        </div>
      </main>
    </>
  );
};
