import { AxiosResponse } from 'axios';
import axiosRetry, { exponentialDelay, isRetryableError } from 'axios-retry';
import { toast } from 'react-toastify';
import { channel } from 'redux-saga';
import { call, delay, put, take, takeEvery } from 'redux-saga/effects';
import { api } from '../../api';
import { Endpoints } from '../../api/endpoints';
import { createCubemap } from '../../functions/cubemap';
import { resize, resizeToBlob } from '../../functions/file';
import { splitImageIntoTiles } from '../../functions/tile';
import { CubeMap, Tile } from '../../types';
import { Guid } from '../../types/api';
import { CreatePanoramaRequest, GetForNewPanoramaResponse } from '../../types/api/panoramas';
import { fetchError } from '../actions/panorama.edit';
import {
  FetchAction,
  FileUploadedAction,
  ProgressAction,
  SavePanoramaAction,
  cubemapGenerated,
  fetchSuccess,
  panoramaProgress,
  savePanoramaError,
  savePanoramaSucess,
  uploadProgress,
} from '../actions/panorama.new';
import { NewPanoramaPageState } from '../reducers/panorama.new';
import { selectState } from '../selectState';

export function* fetchSaga(action: FetchAction) {
  try {
    const response: AxiosResponse<GetForNewPanoramaResponse> = yield call(api().get, Endpoints.Panoramas.New);

    const data = response.data;
    yield put(fetchSuccess(data));
  } catch (error: any) {
    console.error(error);
    if (error.response) {
      yield put(fetchError(error.response));
    }
    yield put(fetchError('Nastala neznámá chyba'));
  }
}

const fileUploadProgressChannel = channel<ProgressAction>();
export function* fileUploadedSaga(action: FileUploadedAction) {
  const file = action.payload.file;
  const cubemap: CubeMap = yield call(createCubemap, file, (progress) =>
    fileUploadProgressChannel.put(panoramaProgress(progress)),
  );

  yield put(cubemapGenerated(cubemap));
}

export function* watchFileUploadProgressChannelSaga() {
  while (true) {
    const action = (yield take(fileUploadProgressChannel)) as ProgressAction;
    yield put(action);
  }
}

export function* saveSaga(action: SavePanoramaAction) {
  const state: NewPanoramaPageState = yield selectState((state) => state.panoramaNew);

  if (state.cubemap === undefined) return;

  const front: string = yield call(resize, state.cubemap.front, 450, 300);
  const request: CreatePanoramaRequest = {
    name: action.payload.title,
    customerId: action.payload.customer,
    enabled: action.payload.enabled,
    expireDate: action.payload.expireDate,
    front: front.replace(/^data:image\/(png|jpg|webp);base64,/, ''),
  };

  try {
    const response: AxiosResponse<Guid> = yield call(api().post, Endpoints.Panoramas.Index, request);
    const id = response.data;

    const faces = [
      {
        faceName: 'd',
        input: state.cubemap.bottom,
      },
      {
        faceName: 'b',
        input: state.cubemap.back,
      },
      {
        faceName: 'l',
        input: state.cubemap.left,
      },
      {
        faceName: 'r',
        input: state.cubemap.right,
      },
      {
        faceName: 'u',
        input: state.cubemap.top,
      },
      {
        faceName: 'f',
        input: state.cubemap.front,
      },
    ];

    const levels = [0, 1, 2, 3, 4];
    const totalRequests = levels
      .map((level) => faces.length * Math.pow(4, Math.max(0, level - 1)))
      .reduce((a, b) => a + b);
    const percentageUnit = totalRequests / 100;

    let sentTiles = 0;
    let percentage = 0;
    for (const level of levels) {
      for (const face of faces) {
        const resizeSize = 512 * Math.pow(2, Math.max(0, level - 1));
        const resized: Blob = yield call(resizeToBlob, face.input, resizeSize, resizeSize);
        const tiles: Tile[] = yield call(splitImageIntoTiles, resized, level);

        for (const tile of tiles) {
          const formData = new FormData();
          formData.append('file', tile.blob);

          const client = api();
          axiosRetry(client, {
            retryCondition: isRetryableError,
            retries: 5,
            retryDelay: exponentialDelay,
          });

          yield call(client.post, Endpoints.Panoramas.Face(id, level, face.faceName, tile.y, tile.x), formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          let newPercentage = Math.floor(sentTiles++ / percentageUnit);
          if (newPercentage > percentage) {
            percentage = newPercentage;
            yield put(uploadProgress(percentage));
          }
        }
      }
    }

    toast(`Panorama ${action.payload.title} vytvořeno`, {
      type: 'success',
    });
    yield delay(1000);
    yield put(savePanoramaSucess());
  } catch (error: any) {
    console.error(error);
    if (error.response) {
      yield put(savePanoramaError());
    }
    yield put(savePanoramaError());
    toast(`Nepodařilo vytvořit panorama`, {
      type: 'error',
    });
  }
}

export function* watchFetchSaga() {
  yield takeEvery('PANORAMA.NEW.FETCH', fetchSaga);
}

export function* watchFileUploadedSaga() {
  yield takeEvery('PANORAMA.NEW.FILE_UPLOADED', fileUploadedSaga);
}

export function* watchSaveSaga() {
  yield takeEvery('PANORAMA.NEW.SAVE', saveSaga);
}

const newPanoramaPageSagas = [
  watchFetchSaga(),
  watchFileUploadedSaga(),
  watchFileUploadProgressChannelSaga(),
  watchSaveSaga(),
];

export default newPanoramaPageSagas;
