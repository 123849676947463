import { InfoHotspot } from '../../types';
import { Guid } from '../../types/api';
import { Hotspot as MarzipanoHotspot, Scene, Viewer } from '../../types/marzipano-types';
import { PanoramaEditAction } from '../actions/panorama.edit';

export type PanoramaEditState = {
  id: Guid;
  title: string;
  customerId: string;
  customerName: string;
  publishDate: Date;
  customization: CustomizationState;
  expireDate: Date;
  enabled: boolean;
  initialYaw: number;
  initialPitch: number;
  infoHotspots: InfoHotspot[];
  infoHotspotModal: InfoHotspotModalState;
  isPlacingInfoHotspot: boolean;
  mainHotspot?: MainHotspotState;
  mainHotspotModal: MainHotspotModalState;
  isPlacingMainHotspot: boolean;
  movingHotspot: MovingHotspotState | undefined;
  viewer: Viewer;
  scene: Scene;
  query: QueryState;
  mutation: MutationState;
};

export type CustomizationState = {
  iconUrl: string;
  mainHotspotBackground: string;
  mainHotspotForeground: string;
  infoHotspotBackground: string;
  infoHotspotForeground: string;
  extendedInfoHotspotBackground: string;
  extendedInfoHotspotForeground: string;
};

export type MovingHotspotState = {
  id: Guid;
  isMain: boolean;
};

type QueryState = {
  state: 'Loading' | 'Error' | 'Success';
  errorMessage: string;
};

type MutationState = {
  state: 'Initial' | 'Loading' | 'Error' | 'Success';
  errorMessage: string;
};

export type InfoHotspotModalState = {
  isNew: boolean;
  isOpen: boolean;
  id: Guid;
  title: string;
  description: string;
  thumbnail: File | undefined;
  thumbnailUrl: string | undefined;
  thumbnailName: string | undefined;
  link: string;
};

export type MainHotspotState = {
  id: Guid;
  title: string;
  yaw: number;
  pitch: number;
  hotspotRef?: MarzipanoHotspot;
  description: string;
  thumbnailUrl: string | undefined;
  thumbnailName: string | undefined;
  matterportLink: string | undefined;
  youtubeLink: string | undefined;
  links: HotspotLink[];
  galleryImages: {
    id: Guid;
    thumbnailUrl: string;
  }[];
};

export type HotspotLink = {
  label: string;
  href: string;
};

export type MainHotspotModalState = {
  isOpen: boolean;
  isNew: boolean;
  title: string;
  description: string;
  thumbnailEdit: boolean;
  thumbnail: File | undefined;
  matterportLink: string;
  youtubeLink: string;
  links: HotspotLink[];
  galleryImages: GalleryImageState[];
  mutation: MutationState;
};

export type GalleryImageState = UntouchedGalleryImage | DeletedGalleryImage | NewGalleryImage;

export type UntouchedGalleryImage = {
  state: 'Untouched';
  id: Guid;
  thumbnailUrl: string;
};

export type DeletedGalleryImage = {
  state: 'Deleted';
  id: Guid;
};

export type NewGalleryImage = {
  state: 'New';
  file: File;
  thumbnailUrl: string;
};

export const initialState: PanoramaEditState = {
  id: '',
  title: '',
  customerId: '',
  customerName: '',
  publishDate: new Date(),
  customization: {
    iconUrl: '',
    mainHotspotBackground: '',
    mainHotspotForeground: '',
    infoHotspotBackground: '',
    infoHotspotForeground: '',
    extendedInfoHotspotBackground: '',
    extendedInfoHotspotForeground: '',
  },
  expireDate: new Date(),
  enabled: false,
  initialYaw: 0,
  initialPitch: 0,
  infoHotspots: [],
  infoHotspotModal: {
    isNew: false,
    isOpen: false,
    id: '',
    title: '',
    thumbnail: undefined,
    thumbnailName: undefined,
    thumbnailUrl: undefined,
    description: '',
    link: '',
  },
  isPlacingInfoHotspot: false,
  mainHotspotModal: {
    isOpen: false,
    isNew: false,
    title: '',
    description: '',
    matterportLink: '',
    youtubeLink: '',
    links: [],
    thumbnailEdit: false,
    thumbnail: undefined,
    galleryImages: [],
    mutation: {
      state: 'Initial',
      errorMessage: '',
    },
  },
  isPlacingMainHotspot: false,
  movingHotspot: undefined,
  viewer: undefined!,
  scene: undefined!,
  query: {
    state: 'Loading',
    errorMessage: '',
  },
  mutation: {
    state: 'Initial',
    errorMessage: '',
  },
};

export default function panoramaEditReducer(
  state: PanoramaEditState = initialState,
  action: PanoramaEditAction,
): PanoramaEditState {
  switch (action.type) {
    case 'PANORAMA.EDIT.FETCH':
      return {
        ...initialState,
        id: action.payload.id,
        query: {
          state: 'Loading',
          errorMessage: '',
        },
      };

    case 'PANORAMA.EDIT.FETCH.SUCCESS':
      return {
        ...state,
        title: action.payload.title,
        customerName: action.payload.customerName,
        customerId: action.payload.customerId,
        publishDate: action.payload.publishDate,
        customization: {
          iconUrl: action.payload.customization.iconUrl,
          mainHotspotBackground: action.payload.customization.mainHotspotBackground,
          mainHotspotForeground: action.payload.customization.mainHotspotForeground,
          infoHotspotBackground: action.payload.customization.infoHotspotBackground,
          infoHotspotForeground: action.payload.customization.infoHotspotForeground,
          extendedInfoHotspotBackground: action.payload.customization.extendedInfoHotspotBackground,
          extendedInfoHotspotForeground: action.payload.customization.extendedInfoHotspotForeground,
        },
        expireDate: action.payload.expireDate,
        enabled: action.payload.enabled,
        initialYaw: +action.payload.initialYaw,
        initialPitch: +action.payload.initialPitch,
        query: {
          state: 'Success',
          errorMessage: '',
        },
      };

    case 'PANORAMA.EDIT.FETCH.ERROR':
      return {
        ...state,
        query: {
          state: 'Error',
          errorMessage: action.payload.message,
        },
      };

    case 'PANORAMA.EDIT.CANVAS.LOADED': {
      return {
        ...state,
        scene: action.payload.scene,
        viewer: action.payload.viewer,
      };
    }

    case 'PANORAMA.EDIT.SAVE': {
      return {
        ...state,
        mutation: {
          state: 'Loading',
          errorMessage: '',
        },
      };
    }

    case 'PANORAMA.EDIT.MOVE_HOTSPOT': {
      return {
        ...state,
        movingHotspot: {
          id: action.payload.id,
          isMain: action.payload.isMain,
        },
      };
    }

    case 'PANORAMA.EDIT.MOVE_HOTSPOT.CANCEL': {
      return {
        ...state,
        movingHotspot: undefined,
      };
    }

    case 'PANORAMA.EDIT.INFO_HOTSPOT.MODAL.OPEN': {
      return {
        ...state,
        infoHotspotModal: {
          id: action.payload?.id ?? '',
          title: action.payload?.title ?? '',
          description: action.payload?.description ?? '',
          link: action.payload?.link ?? '',
          thumbnail: undefined,
          thumbnailUrl: action.payload?.thumbnailUrl,
          thumbnailName: action.payload?.thumbnailName,
          isNew: action.payload === undefined,
          isOpen: true,
        },
      };
    }

    case 'PANORAMA.EDIT.INFO_HOTSPOT.MODAL.CLOSE': {
      return {
        ...state,
        infoHotspotModal: {
          ...state.infoHotspotModal,
          isOpen: false,
        },
      };
    }

    case 'PANORAMA.EDIT.INFO_HOTSPOT.MODAL.CONFIRM': {
      return {
        ...state,
        infoHotspotModal: {
          ...state.infoHotspotModal,
          id: action.payload.id,
          title: action.payload.title,
          description: action.payload.description,
          thumbnail: action.payload.thumbnail,
          link: action.payload.link,
          isOpen: false,
        },
        isPlacingInfoHotspot: true,
      };
    }

    case 'PANORAMA.EDIT.INFO_HOTSPOT.PLACE.CANCEL': {
      return {
        ...state,
        isPlacingInfoHotspot: false,
      };
    }

    case 'PANORAMA.EDIT.INFO_HOTSPOT.CREATE': {
      return {
        ...state,
        isPlacingInfoHotspot: false,
      };
    }

    case 'PANORAMA.EDIT.INFO_HOTSPOT.CREATE.SUCCESS': {
      return {
        ...state,
        infoHotspots: [action.payload, ...state.infoHotspots.filter((hotspot) => hotspot.id !== action.payload.id)],
      };
    }

    case 'PANORAMA.EDIT.INFO_HOTSPOT.DELETE.SUCCESS': {
      return {
        ...state,
        infoHotspots: state.infoHotspots.filter((hotspot) => hotspot.id !== action.payload.id),
      };
    }

    case 'PANORAMA.EDIT.INFO_HOTSPOT.UPDATE.SUCCESS': {
      const index = state.infoHotspots.findIndex((item) => item.id === action.payload.id);
      const updatedHotspots = [...state.infoHotspots];
      updatedHotspots[index] = {
        ...updatedHotspots[index],
        title: action.payload.title,
        yaw: action.payload.yaw,
        pitch: action.payload.pitch,
        description: action.payload.description,
        thumbnailName: action.payload.thumbnailName,
        thumbnailUrl: action.payload.thumbnailUrl,
        link: action.payload.link,
      };

      return {
        ...state,
        movingHotspot: undefined,
        infoHotspotModal: {
          ...state.infoHotspotModal,
          id: '',
          title: '',
          description: '',
          link: '',
          isOpen: false,
        },
        infoHotspots: updatedHotspots,
      };
    }

    case 'PANORAMA.EDIT.MAIN_HOTSPOT.MODAL.OPEN': {
      return {
        ...state,

        mainHotspotModal: {
          ...state.mainHotspotModal,
          title: '',
          description: '',
          youtubeLink: '',
          matterportLink: '',
          ...(state.mainHotspot ?? {}),
          isOpen: true,
          isNew: action.payload === undefined,
          galleryImages:
            action.payload?.galleryImages.map((image) => ({
              state: 'Untouched',
              id: image.id,
              thumbnailUrl: image.thumbnailUrl,
            })) ?? [],
        },
      };
    }

    case 'PANORAMA.EDIT.MAIN_HOTSPOT.MODAL.CLOSE': {
      return {
        ...state,
        mainHotspotModal: {
          ...state.mainHotspotModal,
          isOpen: false,
        },
      };
    }

    case 'PANORAMA.EDIT.MAIN_HOTSPOT.MODAL.CONFIRM': {
      return {
        ...state,
        mainHotspotModal: {
          ...state.infoHotspotModal,
          title: action.payload.title,
          description: action.payload.description,
          matterportLink: action.payload.matterportLink ?? '',
          youtubeLink: action.payload.youtubeLink ?? '',
          links: action.payload.links,
          isOpen: false,
          thumbnailEdit: action.payload.thumbnailEdit,
          thumbnail: action.payload.thumbnail,
          galleryImages: action.payload.galleryImages,
          mutation: {
            state: 'Loading',
            errorMessage: '',
          },
        },
        isPlacingMainHotspot: true,
      };
    }

    case 'PANORAMA.EDIT.MAIN_HOTSPOT.PLACE.CANCEL': {
      return {
        ...state,
        isPlacingMainHotspot: false,
      };
    }

    case 'PANORAMA.EDIT.MAIN_HOTSPOT.CREATE': {
      return {
        ...state,
        isPlacingMainHotspot: false,
      };
    }

    case 'PANORAMA.EDIT.MAIN_HOTSPOT.CREATE.SUCCESS': {
      return {
        ...state,
        mainHotspot: {
          id: action.payload.id,
          title: action.payload.title,
          description: action.payload.description,
          thumbnailName: action.payload.thumbnailName,
          thumbnailUrl: action.payload.thumbnailUrl,
          matterportLink: action.payload.matterportLink,
          youtubeLink: action.payload.youtubeLink,
          links: action.payload.links,
          hotspotRef: action.payload.hotspotRef,
          pitch: action.payload.pitch,
          yaw: action.payload.yaw,
          galleryImages: action.payload.galleryImages,
        },
        mainHotspotModal: {
          ...state.mainHotspotModal,
          isOpen: false,
        },
      };
    }

    case 'PANORAMA.EDIT.MAIN_HOTSPOT.DELETE.SUCCESS': {
      return {
        ...state,
        mainHotspot: undefined,
      };
    }

    case 'PANORAMA.EDIT.MAIN_HOTSPOT.UPDATE': {
      return {
        ...state,
        movingHotspot: undefined,
        mainHotspotModal: {
          ...state.mainHotspotModal,
          mutation: {
            state: 'Loading',
            errorMessage: '',
          },
        },
      };
    }

    case 'PANORAMA.EDIT.MAIN_HOTSPOT.UPDATE.SUCCESS': {
      return {
        ...state,
        movingHotspot: undefined,
        mainHotspotModal: {
          ...state.mainHotspotModal,
          title: '',
          description: '',
          youtubeLink: '',
          matterportLink: '',
          isOpen: false,
          mutation: {
            state: 'Success',
            errorMessage: '',
          },
        },
        mainHotspot: {
          ...state.mainHotspot!,
          title: action.payload.title,
          yaw: action.payload.yaw,
          pitch: action.payload.pitch,
          description: action.payload.description,
          thumbnailName: action.payload.thumbnailName,
          thumbnailUrl: action.payload.thumbnailUrl,
          matterportLink: action.payload.matterportLink,
          youtubeLink: action.payload.youtubeLink,
          galleryImages: action.payload.galleryImages,
        },
      };
    }

    case 'PANORAMA.EDIT.MAIN_HOTSPOT.UPDATE.ERROR': {
      return {
        ...state,
        movingHotspot: undefined,
        mainHotspotModal: {
          ...state.mainHotspotModal,
          mutation: {
            state: 'Error',
            errorMessage: '',
          },
        },
      };
    }

    default:
      return state;
  }
}
