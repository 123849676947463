import { combineReducers } from 'redux';
import account, { AccountState } from './account';
import panoramaEdit, { PanoramaEditState } from './panorama.edit';
import newPanoramaPage, { NewPanoramaPageState } from './panorama.new';

export type StoreState = {
  account: AccountState;
  panoramaNew: NewPanoramaPageState;
  panoramaEdit: PanoramaEditState;
};

const reducer = combineReducers<StoreState>({
  account,
  panoramaNew: newPanoramaPage,
  panoramaEdit,
});

export default reducer;
