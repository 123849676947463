
import { CubeMap } from '../../types';
import { Guid } from '../../types/api';
import { Scene, Viewer } from '../../types/marzipano-types';
import { NewPanoramaAction } from '../actions/panorama.new';

export type State = 'Initial' | 'FileLoading' | 'FileLoaded' | 'UploadingToServer';

export type NewPanoramaPageState = {
  state: State;
  progress: number;
  uploadProgress: number;
  title: string;
  customerId: string;
  cubemap: CubeMap | undefined;
  availableCustomers: { [id: string]: string };
  viewer: Viewer;
  scene: Scene;
  query: QueryState;
  mutation: MutationState;
};

export type InfoHotspotModalState = {
  isNew: boolean;
  isOpen: boolean;
  id: Guid;
  title: string;
  description: string;
  link: string;
};

export type MainHotspotModalState = {
  isOpen: boolean;
  isNew: boolean;
  title: string;
  description: string;
  matterportLink: string;
  youtubeLink: string;
};

type QueryState = {
  state: 'Loading' | 'Error' | 'Success';
  errorMessage: string;
};

type MutationState = {
  state: 'Initial' | 'Loading' | 'Error' | 'Success';
  errorMessage: string;
};

export const initialState: NewPanoramaPageState = {
  state: 'Initial',
  progress: 0,
  uploadProgress: 0,
  title: '',
  customerId: '',
  cubemap: undefined,
  availableCustomers: {},
  viewer: undefined!,
  scene: undefined!,
  query: {
    state: 'Loading',
    errorMessage: '',
  },
  mutation: {
    state: 'Initial',
    errorMessage: '',
  },
};

export default function newPanoramaPageReducer(
  state: NewPanoramaPageState = initialState,
  action: NewPanoramaAction,
): NewPanoramaPageState {
  switch (action.type) {
    case 'PANORAMA.NEW.FETCH':
      return {
        ...initialState,
        query: {
          state: 'Loading',
          errorMessage: '',
        },
      };

    case 'PANORAMA.NEW.FETCH.SUCCESS':
      return {
        ...state,
        availableCustomers: action.payload.availibleCustomers,
        query: {
          state: 'Success',
          errorMessage: '',
        },
      };

    case 'PANORAMA.NEW.FETCH.ERROR':
      return {
        ...state,
        query: {
          state: 'Error',
          errorMessage: action.payload.message,
        },
      };

    case 'PANORAMA.NEW.FILE_UPLOADED':
      return { ...state, state: 'FileLoading', progress: 0 };

    case 'PANORAMA.NEW.PROGRESS':
      return { ...state, progress: action.payload.progress };

    case 'PANORAMA.NEW.CUBEMAP_GENERATED':
      return {
        ...state,
        state: 'FileLoaded',
        cubemap: action.payload.cubemap,
      };

    case 'PANORAMA.NEW.SAVE':
      return {
        ...state,
        state: 'UploadingToServer',
        mutation: {
          state: 'Loading',
          errorMessage: '',
        },
      };

    case 'PANORAMA.NEW.UPLOAD_PROGRESS':
      return {
        ...state,
        uploadProgress: action.payload.progress,
      };

    case 'PANORAMA.NEW.SAVE.SUCCESS':
      return {
        ...state,
        uploadProgress: 0,
        mutation: {
          state: 'Success',
          errorMessage: '',
        },
      };

    case 'PANORAMA.NEW.SAVE.ERROR':
      return {
        ...state,
        mutation: {
          state: 'Error',
          errorMessage: '',
        },
      };

    default:
      return state;
  }
}
