import { Guid } from '../../types/api';
import { GetPanoramaDetailResponse } from '../../types/api/panoramas';
import { Scene, Viewer } from '../../types/marzipano-types';
import { InfoHotspotAction } from './panorama.edit.infoHotspot';
import { MainHotspotAction } from './panorama.edit.mainhotspot';

export type PanoramaEditAction =
  | FetchAction
  | FetchSuccessAction
  | FetchErrorAction
  | CanvasLoadedAction
  | SavePanoramaAction
  | SavePanoramaSucessAction
  | SavePanoramaErrorAction
  | MoveHotspotAction
  | ConfirmMoveHotspotAction
  | CancelMoveHotspotAction
  // ---
  | InfoHotspotAction
  | MainHotspotAction;

// --- Panorama ---

export type FetchAction = {
  type: 'PANORAMA.EDIT.FETCH';
  payload: { id: string };
};

export type FetchSuccessAction = {
  type: 'PANORAMA.EDIT.FETCH.SUCCESS';
  payload: GetPanoramaDetailResponse;
};

export type FetchErrorAction = { type: 'PANORAMA.EDIT.FETCH.ERROR'; payload: { message: string } };

export type CanvasLoadedAction = {
  type: 'PANORAMA.EDIT.CANVAS.LOADED';
  payload: { scene: Scene; viewer: Viewer };
};

export type SavePanoramaAction = {
  type: 'PANORAMA.EDIT.SAVE';
  payload: {
    enabled: boolean;
    yaw: number;
    pitch: number;
    expireDate: Date;
  };
};

export type SavePanoramaSucessAction = {
  type: 'PANORAMA.EDIT.SAVE.SUCCESS';
};

export type SavePanoramaErrorAction = {
  type: 'PANORAMA.EDIT.SAVE.ERROR';
};

export type MoveHotspotAction = {
  type: 'PANORAMA.EDIT.MOVE_HOTSPOT';
  payload: {
    id: Guid;
    isMain: boolean;
  };
};

export type ConfirmMoveHotspotAction = {
  type: 'PANORAMA.EDIT.MOVE_HOTSPOT.CONFIRM';
  payload: {
    yaw: number;
    pitch: number;
  };
};

export type CancelMoveHotspotAction = {
  type: 'PANORAMA.EDIT.MOVE_HOTSPOT.CANCEL';
};

// --- Actions --- Panorama

export const fetch = (id: string): PanoramaEditAction => ({
  type: 'PANORAMA.EDIT.FETCH',
  payload: {
    id: id,
  },
});

export const fetchSuccess = (data: GetPanoramaDetailResponse): PanoramaEditAction => ({
  type: 'PANORAMA.EDIT.FETCH.SUCCESS',
  payload: data,
});

export const fetchError = (message: string): PanoramaEditAction => ({
  type: 'PANORAMA.EDIT.FETCH.ERROR',
  payload: {
    message: message,
  },
});

export const canvasLoaded = (scene: Scene, viewer: Viewer): PanoramaEditAction => ({
  type: 'PANORAMA.EDIT.CANVAS.LOADED',
  payload: {
    scene: scene,
    viewer: viewer,
  },
});

export const savePanorama = (enabled: boolean, yaw: number, pitch: number, expireDate: Date): PanoramaEditAction => ({
  type: 'PANORAMA.EDIT.SAVE',
  payload: {
    enabled: enabled,
    yaw: yaw,
    pitch: pitch,
    expireDate: expireDate,
  },
});

export const savePanoramaSucess = (): PanoramaEditAction => ({
  type: 'PANORAMA.EDIT.SAVE.SUCCESS',
});

export const savePanoramaError = (): PanoramaEditAction => ({
  type: 'PANORAMA.EDIT.SAVE.ERROR',
});

export const moveHotspot = (id: Guid, isMain: boolean): PanoramaEditAction => ({
  type: 'PANORAMA.EDIT.MOVE_HOTSPOT',
  payload: {
    id: id,
    isMain: isMain,
  },
});

export const confirmMoveHotspot = (yaw: number, pitch: number): PanoramaEditAction => ({
  type: 'PANORAMA.EDIT.MOVE_HOTSPOT.CONFIRM',
  payload: {
    yaw: yaw,
    pitch: pitch,
  },
});

export const cancelMoveHotspot = (): PanoramaEditAction => ({
  type: 'PANORAMA.EDIT.MOVE_HOTSPOT.CANCEL',
});
