import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import sagas from './sagas';

import { SelectEffect, select } from 'redux-saga/effects';
import reducer, { StoreState } from './reducers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reduxDevToolsExtensionCompose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeWithDevTools =
  !process.env.REACT_APP_LIB && reduxDevToolsExtensionCompose ? reduxDevToolsExtensionCompose : compose;

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)));

sagaMiddleware.run(sagas);

export default store;

export function selectState<T>(selector: (s: StoreState) => T): SelectEffect {
  return select(selector);
}
