export const CustomersTableHead = () => (
  <thead className="border-b border-slate-200 shadow-sm">
    <tr>
      <th className="px-4 py-3 text-left text-sm font-semibold text-slate-600">Jméno</th>
      <th className="px-4 py-3 text-left text-sm font-semibold text-slate-600">Telefonní číslo</th>
      <th className="px-4 py-3 text-left text-sm font-semibold text-slate-600">Email</th>
      <th className="px-4 py-3 text-left text-sm font-semibold text-slate-600">Počet panoramat</th>
      <th className="px-4 py-3 text-right text-sm font-semibold text-slate-600"></th>
    </tr>
  </thead>
);
