import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { Alert, Button, ButtonLink, Input, Label, Select, Spinner } from '../components/atoms';
import { Modal } from '../components/molecules';
import { Guid } from '../types/api';
import { GetPanoramasResponse, Panorama } from '../types/api/panoramas';

type SortOption = 'Dle nejnovějšího' | 'Dle nejstaršího' | 'Dle nejblížší expirace' | 'Dle názvu';

export const HomePage = () => {
  const queryClient = useQueryClient();

  const [filter, setFilter] = useState('');
  const [customerFilter, setCustomerFilter] = useQueryParam('zakaznik', StringParam);
  const [sort, setSort] = useState<SortOption>('Dle nejnovějšího');
  const [panoramaToDelete, setPanoramaToDelete] = useState<Panorama>();
  const [panoramaDeleteModalOpen, setPanoramaDeleteModalOpen] = useState(false);

  const sortOptions: SortOption[] = ['Dle nejnovějšího', 'Dle nejstaršího', 'Dle nejblížší expirace', 'Dle názvu'];

  const query = useQuery('panoramas', () => api().get<GetPanoramasResponse>(Endpoints.Panoramas.Index), {
    refetchOnWindowFocus: false,
  });

  const panoramas = query.data?.data.panoramas ?? [];
  const availibleCustomers = query.data?.data.availibleCustomers ?? {};
  const filteredPanoramas = [...panoramas]
    .filter(
      (panorama) => filter === '' || panorama.name.normalize().toLowerCase().includes(filter.normalize().toLowerCase()),
    )
    .filter(
      (panorama) =>
        customerFilter === '' ||
        panorama.customerId
          .normalize()
          .toLowerCase()
          .includes((customerFilter ?? '').normalize().toLowerCase()),
    )
    .sort((a, b) =>
      sort === 'Dle nejnovějšího'
        ? new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
        : sort === 'Dle nejstaršího'
        ? new Date(a.publishDate).getTime() - new Date(b.publishDate).getTime()
        : sort === 'Dle nejblížší expirace'
        ? new Date(a.expireDate).getTime() - new Date(b.expireDate).getTime()
        : a.name.localeCompare(b.name),
    );

  const deleteMutation = useMutation((id: Guid) => api().delete(Endpoints.Panoramas.Delete(id)), {
    onSuccess: () => {
      queryClient.invalidateQueries('panoramas');
      setPanoramaDeleteModalOpen(false);
    },
  });

  const openPanoramaLink = (id: string) => {
    // Copy the text inside the text field
    window.open(panoramaLink(id), '_blank');
  };

  const panoramaLink = (id: string) => {
    const port = window.location.port === '' ? '' : `:${window.location.port}`;

    return `${window.location.protocol}//${window.location.hostname}${port}/panorama/${id}/output`;
  };

  return (
    <>
      <main className="p-6">
        <header className="mb-4 flex flex-col justify-between gap-4 xl:flex-row xl:gap-24">
          <div>
            <h1 className="mb-2 text-3xl font-semibold tracking-wide">Panorama</h1>
            <p className="text-sm text-gray-500">Přehled všech panoramat pro zákazníky</p>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <Label htmlFor="search">Hledat</Label>
              <Input
                icon="search"
                type="search"
                id="search"
                name="search"
                value={filter}
                onChange={(value) => setFilter(value)}
              />
            </div>
            <div>
              <Label htmlFor="customer">Zákazník</Label>
              <Select
                icon="user"
                id="search"
                name="customer"
                options={[{ label: 'Všichni', value: '' }].concat(
                  Object.keys(availibleCustomers)
                    .map((key) => ({ label: availibleCustomers[key], value: key }))
                    .sort(),
                )}
                value={customerFilter ?? ''}
                onChange={(value) => setCustomerFilter(value)}
              />
            </div>
            <div>
              <Label htmlFor="sort">Seřadit</Label>
              <Select
                icon="sort"
                id="search"
                name="sort"
                value={sort}
                options={sortOptions}
                onChange={(value) => setSort(value as SortOption)}
              />
            </div>
          </div>
        </header>
        <section className="pb-16">
          {query.isLoading || query.isRefetching ? (
            <Spinner className="mx-auto my-8 h-8 w-8 text-blue-700"></Spinner>
          ) : query.isError ? (
            <Alert variant="error">Nepodařilo se načíst data</Alert>
          ) : filteredPanoramas.length === 0 ? (
            <Alert variant="info"> Nenalezeno žádné panorama.</Alert>
          ) : (
            <div className="overflow-hidden rounded-md border border-slate-200 bg-white shadow">
              <table className="w-full">
                <thead className="border-b border-slate-200 shadow-sm">
                  <tr>
                    <th className="px-4 py-3 text-left text-sm font-semibold text-slate-600">Název</th>
                    <th className="px-4 py-3 text-left text-sm font-semibold text-slate-600"></th>
                    <th className="px-4 py-3 text-left text-sm font-semibold text-slate-600">Zákazník</th>
                    <th className="px-4 py-3 text-left text-sm font-semibold text-slate-600">Datum vytvoření</th>
                    <th className="px-4 py-3 text-left text-sm font-semibold text-slate-600">Datum expirace</th>
                    <th className="px-4 py-3 text-left text-sm font-semibold text-slate-600">Zapnuto</th>
                    <th className="px-4 py-3 text-right text-sm font-semibold text-slate-600"></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPanoramas.map((panorama) => (
                    <tr key={panorama.id} className="border-b border-slate-100">
                      <td className="w-px px-4 py-3 text-sm">
                        <Link
                          to={`/panorama/upravit/${panorama.id}`}
                          className="block h-16  w-[92px] overflow-hidden rounded"
                          title={panorama.name}
                        >
                          <img
                            src={panorama.thumbnailPath}
                            alt={`${panorama.customerName} - ${panorama.name}`}
                            className=" transform rounded object-cover object-center transition duration-500 hover:scale-105"
                          />
                        </Link>
                      </td>
                      <td className="px-4 py-3 text-sm font-semibold text-slate-800">
                        <Link
                          to={`/panorama/upravit/${panorama.id}`}
                          className="block overflow-hidden rounded transition hover:text-slate-900"
                        >
                          {panorama.name}
                        </Link>
                      </td>
                      <td className="px-4 py-3 text-sm text-slate-800">{panorama.customerName}</td>
                      <td className="px-4 py-3 text-sm text-slate-800">
                        {new Date(panorama.publishDate).toLocaleDateString('cs')}
                      </td>
                      <td className="px-4 py-3 text-sm text-slate-800">
                        {new Date(panorama.expireDate).toLocaleDateString('cs')}
                      </td>
                      <td className="px-4 py-3 text-sm text-slate-800">
                        {panorama.enabled ? (
                          <span className="block w-fit rounded-full bg-green-600 px-3 py-0.5 align-bottom text-white">
                            Ano
                          </span>
                        ) : (
                          <span className="block w-fit rounded-full bg-red-600 px-3 py-0.5 align-bottom text-white">
                            Ne
                          </span>
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-slate-800">
                        <div className="flex justify-end gap-4">
                          <Button
                            onClick={() => {
                              setPanoramaToDelete(panorama);
                              setPanoramaDeleteModalOpen(true);
                            }}
                            variant="gray"
                            size="small"
                            icon="trash"
                          >
                            Odstranit
                          </Button>
                          <Button
                            onClick={() => openPanoramaLink(panorama.id)}
                            variant="gray"
                            icon="share"
                            size="small"
                          >
                            Výstup
                          </Button>
                          <ButtonLink
                            to={`/panorama/upravit/${panorama.id}`}
                            variant="blue"
                            icon="pencil-alt"
                            size="small"
                          >
                            Upravit
                          </ButtonLink>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </section>
        <section className="fixed bottom-4 right-8 z-20 shadow-lg shadow-blue-500/20">
          <ButtonLink variant="blue" icon="plus-square" to={`/panorama/nove`}>
            Vytvořit nové panorama
          </ButtonLink>
        </section>
      </main>
      <Modal
        isOpen={panoramaDeleteModalOpen}
        onClose={() => {}}
        title={`Odstranit panorama`}
        body={
          <div>
            <Alert variant="error">
              <span>
                Opravdu si přejete odstranit panorama <strong>{panoramaToDelete?.name}</strong> pro zákazníka{' '}
                <strong>{panoramaToDelete?.customerName}</strong>? Tuto akci nelze vzít zpět!
              </span>
            </Alert>
          </div>
        }
        footer={
          <>
            <Button
              onClick={() => {
                setPanoramaDeleteModalOpen(false);
              }}
              variant="gray"
              size="regular"
              icon="times"
            >
              Zrušit
            </Button>
            <Button
              onClick={() => {
                panoramaToDelete && deleteMutation.mutate(panoramaToDelete.id);
              }}
              variant="red"
              size="regular"
              icon="trash"
            >
              Potvrdit
            </Button>
          </>
        }
      />
    </>
  );
};
