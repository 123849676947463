import { parseJwt } from '../../functions/jwtToken';
import { Role } from '../../types/api/account';
import { AuthAction } from '../actions/account';

export type AccountState = {
  isLoggedIn: boolean;
  email: string;
  token: string;
  role: Role;
};

export const initialState: AccountState = {
  isLoggedIn: false,
  email: '',
  token: '',
  role: 'Customer',
};

export default function accountReducer(state: AccountState = initialState, action: AuthAction): AccountState {
  switch (action.type) {
    case 'ACCOUNT.LOGIN.SUCCESS':
      const parsedToken = parseJwt(action.payload.token);

      return {
        ...state,
        isLoggedIn: true,
        email: parsedToken.name,
        token: action.payload.token,
        role: parsedToken.role as Role,
      };

    case 'ACCOUNT.LOGOUT':
      return {
        ...state,
        isLoggedIn: false,
        token: '',
        email: '',
      };

    default:
      return state;
  }
}
