import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from '../../utils';

type Props = {
  files: {
    thumbnailUrl: string;
  }[];
  onAddFiles: (files: File[]) => void;
  onMoveLeft: (index: number) => void;
  onMoveRight: (index: number) => void;
  onDelete: (index: number) => void;
};

export const GalleryInput = ({ files, onAddFiles, onMoveLeft, onMoveRight, onDelete }: Props) => {
  const addFiles = (acceptedFiles: FileList | null) => {
    const files = acceptedFiles === null ? [] : Array.from(acceptedFiles);
    onAddFiles(files);
  };

  return (
    <div className="overflow-hidden rounded border border-slate-300 shadow-sm">
      <div className="group relative border-b  border-slate-300 bg-slate-200 text-slate-700 shadow">
        <input
          type="file"
          multiple
          onChange={(e) => addFiles(e.target.files)}
          className="w-full cursor-pointer bg-slate-200 px-4 py-2"
        />
        <p className="pointer-events-none absolute inset-0 flex cursor-pointer items-center bg-slate-200 px-4 py-2 text-sm transition duration-300 group-hover:bg-slate-300">
          Klikněte pro přídání souborů
        </p>
      </div>
      <div className={classNames('grid grid-cols-4 gap-4', files.length === 0 ? '' : 'p-3')}>
        {files.map((file, i) => (
          <div className="overflow-hidden rounded" key={file.thumbnailUrl}>
            <img src={file.thumbnailUrl} alt="" className="block aspect-[3/2] object-cover" />
            <div className="grid grid-cols-3">
              <button
                title="Posunoout doleva"
                className="rounded-bl bg-slate-500 px-2 py-2 text-xs text-white transition hover:bg-slate-600"
                onClick={() => onMoveLeft(i)}
                type="button"
              >
                <FontAwesomeIcon icon="arrow-left" />
              </button>
              <button
                title="Odstranit"
                className="bg-red-500 px-2 py-2 text-xs text-white transition hover:bg-red-600"
                type="button"
                onClick={() => onDelete(i)}
              >
                <FontAwesomeIcon icon="trash" />
              </button>
              <button
                title="Posunout doprava"
                className="rounded-br bg-slate-500 px-2 py-2 text-xs text-white transition hover:bg-slate-600"
                onClick={() => onMoveRight(i)}
                type="button"
              >
                <FontAwesomeIcon icon="arrow-right" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
