import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useEffect, useState } from 'react';
import { Button, FileInput, Input, Label } from '../../components/atoms';
import { Modal } from '../../components/molecules';

type Props = {
  isOpen: boolean;
  hotspot: ModalData;
  onClose: () => void;
  onConfirm: (data: ConfirmData) => void;
};

type ModalData = {
  id?: string;
  title: string;
  description: string;
  thumbnailUrl?: string;
  thumbnailName?: string;
  link: string;
};

type ConfirmData = {
  id?: string;
  title: string;
  description: string;
  thumbnailEdit: boolean;
  thumbnail: File | undefined;
  link: string;
};

export const InfoHotspotModal = ({ isOpen, hotspot, onClose, onConfirm }: Props) => {
  const [title, setTitle] = useState('');
  const [descriptionEditor, setDescriptionEditor] = useState<ClassicEditor | undefined>(undefined);
  const [description, setDescription] = useState('');
  const [thumbnailName, setThumbnailName] = useState(hotspot.thumbnailName);
  const [thumbnailEdit, setThumbnailEdit] = useState(false);
  const [thumbnail, setThumbnail] = useState<File>();
  const [link, setLink] = useState('');

  const confirm = () => {
    onConfirm({ id: hotspot.id, title, description: descriptionEditor!.getData(), thumbnailEdit, thumbnail, link });
  };

  useEffect(() => {
    if (isOpen) {
      setTitle(hotspot.title);
      setDescription(hotspot.description);
      setLink(hotspot.link);
      setThumbnailName(hotspot.thumbnailName);
    }
  }, [hotspot, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={hotspot.id ? 'Upravit hotspot' : 'Vytvořit hotspot'}
      body={
        <form
          className="space-y-4"
          onSubmit={(e) => {
            e.preventDefault();
            confirm();
          }}
          action=""
        >
          <div>
            <Label htmlFor="hotspotName">Název hotspotu</Label>
            <Input
              name="hotspotName"
              id="hotspotName"
              type="text"
              value={title}
              onChange={(value) => setTitle(value)}
            />
          </div>
          <div>
            <Label htmlFor="currentHotspotDescription">Popis hotspotu</Label>
            <CKEditor
              editor={Editor}
              data={description}
              onReady={(editor) => {
                setDescriptionEditor(editor);
              }}
              config={{
                link: {
                  addTargetToExternalLinks: true,
                },
              }}
            />
          </div>
          <div>
            <Label htmlFor="main-hotspot-modal-photo">Úvodní fotografie</Label>
            <FileInput
              name="main-hotspot-modal-photo"
              id="main-hotspot-modal-photo"
              icon="image"
              onChange={(file) => {
                setThumbnailName(file.name);
                setThumbnail(file);
                setThumbnailEdit(true);
              }}
              onRemove={() => {
                setThumbnailName(undefined);
                setThumbnail(undefined);
                setThumbnailEdit(true);
              }}
              fileName={thumbnailName}
            />
          </div>
          <div>
            <Label htmlFor="hotspotLink">Odkaz v hotspotu</Label>
            <Input
              name="hotspotLink"
              id="hotspotLink"
              type="text"
              value={link}
              onChange={(value) => setLink(value)}
              icon="link"
            />
            <p className="mt-1.5 pl-1 text-xs font-light text-slate-600">
              Adresu odkazu zadávejte jako absolutní cestu (např: https://seznam.cz){' '}
            </p>
          </div>
        </form>
      }
      footer={
        <>
          <Button variant="gray" onClick={onClose} size="small">
            Zrušit
          </Button>
          <Button variant="blue" onClick={confirm} size="small">
            Potvrdit
          </Button>
        </>
      }
    />
  );
};
