import { CubeMap } from "../types";

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');
const facePositions = {
  front: { x: 1, y: 1 },
  back: { x: 3, y: 1 },
  right: { x: 2, y: 1 },
  left: { x: 0, y: 1 },
  top: { x: 1, y: 0 },
  bottom: { x: 1, y: 2 },
};

let finished = 0;
let workers: Worker[] = [];
let progress = 0;

export function createCubemap(file: File, progressFn: (progress: number) => void): Promise<CubeMap> {
  if (!file || !ctx) {
    return new Promise<CubeMap>(undefined!);
  }

  const img = new Image();

  return new Promise<CubeMap>((resolve) => {
    img.src = URL.createObjectURL(file);
    img.addEventListener('load', () => {
      const { width, height } = img;
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0);
      const data = ctx.getImageData(0, 0, width, height);

      for (let worker of workers) {
        worker.terminate();
      }
      const cubemap: CubeMap = {
        front: '',
        back: '',
        right: '',
        left: '',
        top: '',
        bottom: '',
      };

      for (let [faceName] of Object.entries(facePositions)) {
        const options = {
          data: data,
          face: faceName,
          rotation: (Math.PI * 180) / 180,
          interpolation: 'lanczos',
        };

        const worker = new Worker('/convert.js');

        // eslint-disable-next-line no-loop-func
        const setDownload = (data: ImageData) => {
          cubemap[faceName] = getDataURL(data).replace(/^data:image\/(png|jpg);base64,/, '');

          finished++;

          if (finished === 6) {
            finished = 0;
            workers = [];
            progress = 0;
            resolve(cubemap);
          }
        };

        // eslint-disable-next-line no-loop-func
        worker.onmessage = (message) => {
          if (message.data.type === 'progress') {
            progress++;
            if (progress % 6 === 0) {
              progressFn(progress / 6);
            }
          } else if (message.data.type === 'finished') {
            setDownload(message.data.value);
          }
        };
        worker.postMessage(options);

        workers.push(worker);
      }
    });
  });
}

function getDataURL(imgData: ImageData) {
  if (!ctx) return '';

  canvas.width = imgData.width;
  canvas.height = imgData.height;
  ctx.putImageData(imgData, 0, 0);
  return canvas.toDataURL();
}
