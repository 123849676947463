import { Tile } from '../types';

export function splitImageIntoTiles(input: Blob, level: number) {
  return new Promise<Tile[]>((resolve, reject) => {
    const res: Tile[] = [];
    const image = new Image();
    image.src = URL.createObjectURL(input);

    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext('2d');
      context!.drawImage(image, 0, 0);

      const tileSize = Math.pow(2, Math.max(0, level - 1));
      const tileWidth = Math.floor(image.width / tileSize);
      const tileHeight = Math.floor(image.height / tileSize);

      for (let y = 0; y < tileSize; y++) {
        for (let x = 0; x < tileSize; x++) {
          const tileCanvas = document.createElement('canvas');
          tileCanvas.width = tileWidth;
          tileCanvas.height = tileHeight;
          const tileContext = tileCanvas.getContext('2d');

          tileContext!.drawImage(
            canvas,
            x * tileWidth,
            y * tileHeight,
            tileWidth,
            tileHeight,
            0,
            0,
            tileWidth,
            tileHeight,
          );

          tileCanvas.toBlob(
            (blob) => {
              res.push({ x, y, blob: blob as Blob });

              if (res.length === tileSize * tileSize) {
                resolve(res);
              }
            },
            'image/webp',
            0.85,
          );
        }
      }
    };
  });
}
