import { Transition } from '@headlessui/react';
import { Alert, Button } from '../../../components/atoms';
import { Viewer } from '../../../types/marzipano-types';
import { classNames } from '../../../utils';

type Props = {
  onConfirmHotspotPlace: (yaw: number, pitch: number) => void;
  onCancelHotspotPlace: () => void;
  isPlacingHotspot: boolean;
  viewer: Viewer;
};

export const PanoramaCanvas = ({ onConfirmHotspotPlace, onCancelHotspotPlace, isPlacingHotspot, viewer }: Props) => {
  const confirmPlace = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const targetBoudingClientRect = event.currentTarget.getBoundingClientRect();
    const coords = viewer.view().screenToCoordinates({
      x: event.clientX - targetBoudingClientRect.left,
      y: event.clientY - targetBoudingClientRect.top,
    });
    const yaw = coords.yaw;
    const pitch = coords.pitch;

    onConfirmHotspotPlace(yaw, pitch);
  };

  return (
    <section className="relative flex-grow p-6">
      <div className={classNames('absolute inset-0 z-[530] h-full w-full bg-slate-900')} id="panorama-canvas" />
      {/* hotspot adding */}
      <Transition
        show={isPlacingHotspot}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        as="div"
      >
        {/* Canvas overlay */}
        <div className="absolute inset-0 z-[530] h-full w-full" onClick={(event) => confirmPlace(event)} />
        <div className="absolute left-1/2 top-6 z-[531] -translate-x-1/2 shadow-xl shadow-blue-500/20">
          <Alert variant="info">Umístěte váš hotspot</Alert>
        </div>
        <div className="absolute right-6 bottom-6 z-[531] shadow-xl shadow-red-500/20">
          <Button variant="red" icon="times" onClick={onCancelHotspotPlace}>
            Zrušit
          </Button>
        </div>
      </Transition>
    </section>
  );
};
