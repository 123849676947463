import { CubeGeometry, ImageUrlSource, RectilinearView, Viewer as ViewerConstructor } from 'marzipano';
import React from 'react';
import ReactDOM from 'react-dom';
import { url as apiUrl } from '../api';
import { InfoHotspot, InfoHotspotWithLink, MainHotspot } from '../components/organisms';
import { Scene, Viewer } from '../types/marzipano-types';

export const createViewerAndScene = (yaw: number, pitch: number, panoramaId: string) => {
  const { geometry, source, view } = getMarzipanoViewParameters(yaw, pitch, panoramaId);
  const canvaselement = document.getElementById('panorama-canvas');
  const viewer: Viewer = new ViewerConstructor(canvaselement, {
    controls: {
      mouseViewMode: 'drag',
    },
  });
  const scene = viewer.createScene({ source, geometry, view });
  scene.switchTo({});

  return { viewer, scene };
};

export const getMarzipanoViewParameters = (yaw: number, pitch: number, panoramaId: string) => {
  const defaultResolution = (100 * Math.PI) / 180;
  const defaultFov = (120 * Math.PI) / 180;
  const defaultViewParams = {
    yaw: yaw,
    pitch: pitch,
    roll: 0,
    fov: defaultFov,
  };
  const defaultViewLimiter = RectilinearView.limit.traditional(5750, defaultResolution, defaultFov);

  const geometry = new CubeGeometry([
    {
      tileSize: 256,
      size: 256,
      fallbackOnly: true,
    },
    {
      tileSize: 512,
      size: 512,
    },
    {
      tileSize: 512,
      size: 1024,
    },
    {
      tileSize: 512,
      size: 2048,
    },
    {
      tileSize: 512,
      size: 4096,
    },
  ]);
  const source = ImageUrlSource.fromString(`${apiUrl}images/${panoramaId}/{z}/{f}/{y}/{x}.webp`);
  const view = new RectilinearView(defaultViewParams, defaultViewLimiter);

  return { geometry, source, view };
};

export const renderInfoHotspot = (
  scene: Scene,
  yaw: number,
  pitch: number,
  name: string,
  description: string,
  link: string | undefined,
  thumbnailUrl: string | undefined,
  customization: {
    infoHotspotBackground: string;
    infoHotspotForeground: string;
    extendedInfoHotspotBackground: string;
    extendedInfoHotspotForeground: string;
  },
) => {
  const element = document.createElement('div');
  element.classList.add('hover:z-[70]');
  const hotspotRef = scene!.hotspotContainer().createHotspot(element, { yaw, pitch }, { perspective: {} });

  renderInfoHotspotToElement(name, description, link, thumbnailUrl, customization, element);

  return hotspotRef;
};

export const renderInfoHotspotToElement = (
  name: string,
  description: string,
  link: string | undefined,
  thumbnailUrl: string | undefined,
  customization: {
    infoHotspotBackground: string;
    infoHotspotForeground: string;
    extendedInfoHotspotBackground: string;
    extendedInfoHotspotForeground: string;
  },
  element: Element,
) => {
  if (link) {
    ReactDOM.render(
      React.createElement(() =>
        InfoHotspotWithLink({
          name,
          description,
          link,
          thumbnailUrl,
          style: {
            background: customization.extendedInfoHotspotBackground,
            foreground: customization.extendedInfoHotspotForeground,
          },
        }),
      ),
      element,
    );
  } else {
    ReactDOM.render(
      React.createElement(() =>
        InfoHotspot({
          name,
          description,
          thumbnailUrl,
          style: {
            background: customization.infoHotspotBackground,
            foreground: customization.infoHotspotForeground,
          },
        }),
      ),
      element,
    );
  }
};

export const renderMainHotspot = (
  scene: Scene,
  yaw: number,
  pitch: number,
  name: string,
  description: string,
  matterportLink: string | undefined,
  youtubeLink: string | undefined,
  links: {
    label: string;
    href: string;
  }[],
  thumbnailUrl: string | undefined,
  galleryImages: {
    url: string;
    thumbnailUrl: string;
  }[],
  style: {
    iconUrl: string;
    background: string;
    foreground: string;
  },
) => {
  const element = document.createElement('div');
  element.classList.add('hover:z-[70]');
  const hotspotRef = scene.hotspotContainer().createHotspot(element, { yaw, pitch }, { perspective: {} });

  renderMainHotspotToElement(
    name,
    description,
    matterportLink,
    youtubeLink,
    links,
    thumbnailUrl,
    galleryImages,
    style,
    element,
  );

  return hotspotRef;
};

export const renderMainHotspotToElement = (
  name: string,
  description: string,
  matterportLink: string | undefined,
  youtubeLink: string | undefined,
  links: {
    label: string;
    href: string;
  }[],
  thumbnailUrl: string | undefined,
  galleryImages: {
    url: string;
    thumbnailUrl: string;
  }[],
  style: {
    iconUrl: string;
    background: string;
    foreground: string;
  },
  element: Element,
) => {
  ReactDOM.render(
    React.createElement(() =>
      MainHotspot({
        name,
        description,
        matterportLink,
        youtubeLink,
        links,
        thumbnailUrl,
        galleryImages,
        style,
      }),
    ),
    element,
  );
};
