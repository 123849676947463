import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from '../../utils';
import { Spinner } from './Spinner';

type Props = {
  to: string;
  children: ReactNode;
  variant: 'blue' | 'gray';
  size?: 'regular' | 'small' | 'smallSquare';
  isLoading?: boolean;
  isDisabled?: boolean;
  icon?: IconName;
};

export const ButtonLink = ({
  to,
  children,
  variant,
  isLoading,
  isDisabled,
  icon,
  size = 'regular',
}: Props) => (
  <Link
    to={to}
    type="button"
    className={classNames(
      'inline-flex items-center justify-center rounded-md border shadow-sm transition focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed',
      variant === 'blue' &&
        'border-transparent bg-blue-500 text-gray-50 hover:bg-blue-600 hover:text-white focus:bg-blue-700 focus:text-white focus:ring-blue-500',
      variant === 'gray' &&
        'border-gray-300 bg-white text-gray-700 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800 focus:bg-gray-300 focus:text-gray-800 focus:ring-gray-500',
      size === 'regular' && 'gap-3 px-4 py-2 text-sm font-medium tracking-wide',
      size === 'small' && 'gap-3 px-4 py-2 text-sm font-medium tracking-wide',
      size === 'smallSquare' && 'gap-3 px-2 py-2 text-sm font-medium',
    )}
  >
    <>
      {isLoading && <Spinner className="h-4 w-4"></Spinner>}
      {!isLoading && icon && <FontAwesomeIcon icon={icon} className="h-4 w-4"></FontAwesomeIcon>}
      {children}
    </>
  </Link>
);
