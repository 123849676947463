import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { classNames } from '../../utils';

type Props = {
  variant: 'info' | 'error' | 'success' | 'warning';
  children: ReactNode;
  onClose?: () => void;
};

export const Alert = ({ variant, children, onClose }: Props) => {
  return (
    <div
      className={classNames(
        'flex items-center   gap-6 rounded px-4 py-2.5 text-sm tracking-wide',
        variant === 'info' && ' bg-info-100 text-info-700',
        variant === 'error' && ' bg-red-100 text-red-700',
        variant === 'success' && ' bg-green-100 text-green-700',
        variant === 'warning' && 'bg-orange-200 text-orange-800',
      )}
      role="alert"
    >
      <FontAwesomeIcon
        icon={
          variant === 'info'
            ? 'info-circle'
            : variant === 'error'
            ? 'exclamation-circle'
            : variant === 'success'
            ? 'check-circle'
            : 'exclamation-circle'
        }
        className="-mr-2 "
      />
      <span className="align-middle">{children}</span>
      {onClose && (
        <button onClick={() => onClose()} className="ml-auto rounded-md px-1">
          <FontAwesomeIcon icon="times" />
        </button>
      )}
    </div>
  );
};
