export type AuthAction = LoginSuccessAction | { type: 'ACCOUNT.LOGOUT' };

export type LoginSuccessAction = { type: 'ACCOUNT.LOGIN.SUCCESS'; payload: { token: string } };

export const loginSuccess = (token: string): AuthAction => ({
  type: 'ACCOUNT.LOGIN.SUCCESS',
  payload: {
    token,
  },
});

export const logout = (): AuthAction => ({
  type: 'ACCOUNT.LOGOUT',
});
