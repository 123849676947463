import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { api, toastError } from '../api';
import { Endpoints } from '../api/endpoints';
import { Button, Logo } from '../components/atoms';
import { InputField } from '../components/molecules';
import { loginSuccess } from '../redux/actions/account';
import { LoginRequest, LoginResponse } from '../types/api/account';

type LoginFormState = {
  email: string;
  password: string;
};

export function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const mutation = useMutation((request: LoginRequest) => api().post<LoginResponse>(Endpoints.Account.Login, request), {
    onSuccess: (response) => {
      dispatch(loginSuccess(response.data.token));
      history.push('/');
    },
    onError: (error: AxiosError) => {
      toastError(error, 'Nepodařilo se přihlásit');
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormState>({ mode: 'onBlur', shouldFocusError: false });

  return (
    <main className="flex h-full flex-col justify-center bg-slate-900">
      <div className="flex flex-1 flex-col items-center justify-center py-12  sm:px-6 lg:px-8">
        <section className="min-w-[28rem] max-w-md rounded-lg bg-white shadow-2xl">
          <div className="border-b px-8 pb-4 pt-6 shadow">
            <div className="text-slate-7 00 mb-4 flex items-center justify-center gap-4 text-xl font-semibold  uppercase">
              <Logo />
              <div>Prezentace Nemovitostí</div>
            </div>
            <h1 className="text-center text-4xl font-bold uppercase text-blue-500">Flypano</h1>
          </div>
          <form className="space-y-4 px-8 py-6" onSubmit={handleSubmit((data) => mutation.mutate({ ...data }))}>
            <h2 className="text-center text-xl text-gray-900">Přihlašte se do svého účtu</h2>

            <InputField
              type="email"
              label="Email"
              register={register('email', {
                required: 'Email musí být vyplněn',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Zadejte platnou emailovou adresu',
                },
              })}
              error={errors.email}
            />
            <InputField
              type="password"
              label="Heslo"
              register={register('password', { required: 'Heslo musí být vyplněno' })}
              error={errors.password}
            />
            <Button type="submit" variant="blue" icon="sign-in-alt" isLoading={mutation.isLoading} isFullWidth>
              Přihlásit
            </Button>
          </form>
        </section>
      </div>
      <footer className="flex justify-center gap-3 px-4 py-3 text-sm font-medium text-slate-400">
        <a
          href="https://prezentacenemovitosti.cz/zasady-zpracovani-osobnich-udaju"
          className="underline decoration-slate-100/0 underline-offset-4 transition duration-300 hover:text-slate-100 hover:decoration-slate-100"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ochrana osobních údajů
        </a>
        <div className="my-auto h-[16px] w-[1.5px] bg-slate-400"></div>
        <a
          href="https://prezentacenemovitosti.cz"
          className="underline decoration-slate-100/0 underline-offset-4 transition duration-300 hover:text-slate-100 hover:decoration-slate-100"
          target="_blank"
          rel="noopener noreferrer"
        >
          Provozovatel - Prezentace Nemovitostí
        </a>
      </footer>
    </main>
  );
}
