import { ReactNode } from 'react';
import { classNames } from '../../utils';

type Props = {
  htmlFor: string;
  children: ReactNode;
  size?: 'regular' | 'tiny';
};

export const Label = ({ htmlFor, children, size = 'regular' }: Props) => (
  <label
    htmlFor={htmlFor}
    className={classNames('mb-1 block text-gray-600', size === 'regular' ? ' text-sm' : 'text-xs')}
  >
    {children}
  </label>
);
