import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PanoramaLoading = () => (
  <div className="flex h-full w-full flex-col items-center justify-center border-l border-t border-slate-700 bg-slate-900 shadow-inner">
    <div className="pointer-events-none relative mb-4">
      <FontAwesomeIcon icon="image" size="5x" className="text-blue-500" />
      <span className="absolute -right-5 -bottom-1 flex h-9 w-9 animate-spin items-center justify-center rounded-full bg-slate-900 text-blue-500">
        <FontAwesomeIcon icon="circle-notch" size="lg" />
      </span>
    </div>
    <div className="text-lg text-slate-200">Načítám panorama</div>
  </div>
);
