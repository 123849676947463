import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from '../../utils';

type Props = {
  name: string;
  id: string;
  value: string;
  onChange: (value: string) => void;
  type: 'email' | 'text' | 'search' | 'tel' | 'password' | 'file' | 'textarea' | 'date' | 'color';
  size?: 'regular' | 'tiny';
  icon?: IconProp;
  disabled?: boolean;
};

export const Input = ({ name, id, value, type, onChange, icon, disabled, size = 'regular' }: Props) => {
  const className = classNames(
    'mt-1 block w-full border rounded-md overflow-hidden border-slate-300 transition hover:border-blue-500 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-800/10',
    'disabled:bg-slate-100 disabled:text-slate-600 disabled:hover:border-slate-400 disabled:border-slate-300 disabled:cursor-not-allowed',
    size === 'regular' && 'px-4 py-2 text-sm font-medium tracking-wide',
    size === 'tiny' && 'px-3.5 py-1.5 text-[13px] font-medium tracking-wide',
    icon && type !== 'textarea' ? 'pl-14' : '',
  );

  const iconClassName = classNames(
    'absolute left-px top-px bottom-px flex items-center justify-center border-r px-2 text-slate-500 shadow-sm transition group-focus-within:border-blue-500 group-focus-within:bg-blue-500/20 group-focus-within:text-blue-500 rounded-l',
    disabled ? 'bg-slate-200 border-slate-300' : 'bg-slate-200 border-slate-300',
  );

  return (
    <div className="group relative shadow-sm">
      {type !== 'textarea' && (
        <>
          <input
            type={type}
            name={name}
            id={id}
            value={value}
            disabled={disabled}
            onChange={(e) => onChange(e.currentTarget.value)}
            className={className}
          />
          {icon && (
            <div className={iconClassName}>
              <FontAwesomeIcon icon={icon} className="w-6"></FontAwesomeIcon>
            </div>
          )}
        </>
      )}
      {type === 'textarea' && (
        <textarea
          name={name}
          id={id}
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
          disabled={disabled}
          className={className}
          rows={3}
        />
      )}
    </div>
  );
};
