import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import { AxiosError } from 'axios';
import { autorotate as MarzipanoAutorotate } from 'marzipano';
import { Fragment, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { Spinner } from '../components/atoms';
import { createViewerAndScene, renderInfoHotspot, renderMainHotspot } from '../functions/panorama';
import { GetPanoramaOutputResponse } from '../types/api/panoramas';
import { Viewer } from '../types/marzipano-types';

type UrlParams = {
  id: string;
};

export const PanoramaOutputPage = () => {
  const id = useParams<UrlParams>().id;
  const [isRotating, setIsRotating] = useState(true);
  const [viewer, setViewer] = useState<Viewer>();

  useEffect(() => {
    const autorotate = MarzipanoAutorotate({
      yawSpeed: 0.03,
      targetPitch: 0,
      targetFov: Math.PI / 2,
    });

    if (viewer) {
      if (isRotating) {
        viewer.setIdleMovement(3000, autorotate);
        viewer.startMovement(autorotate);
      } else {
        viewer.stopMovement();
      }
    }
  }, [viewer, isRotating]);

  const query = useQuery(
    ['panorama', 'output', id],
    () => api().get<GetPanoramaOutputResponse>(Endpoints.Panoramas.Output(id)),
    {
      onSuccess: (response) => {
        loadPanorama(response.data);
      },
      onError: (error: AxiosError) => {},
      refetchOnWindowFocus: false,
      retry: 1,
    },
  );

  const loadPanorama = (response: GetPanoramaOutputResponse) => {
    const { viewer, scene } = createViewerAndScene(+response.initialYaw, +response.initialPitch, id);
    const { mainHotspot, infoHotspots } = response;

    if (mainHotspot)
      renderMainHotspot(
        scene,
        +mainHotspot.yaw,
        +mainHotspot.pitch,
        mainHotspot.title,
        mainHotspot.description,
        mainHotspot.matterportLink,
        mainHotspot.youtubeLink,
        mainHotspot.links,
        mainHotspot.thumbnailUrl,
        mainHotspot.galleryImages,
        {
          iconUrl: response.customization.iconUrl,
          background: response.customization.mainHotspotBackground,
          foreground: response.customization.mainHotspotForeground,
        },
      );

    infoHotspots.forEach((hotspot) => {
      renderInfoHotspot(
        scene,
        +hotspot.yaw,
        +hotspot.pitch,
        hotspot.title,
        hotspot.description,
        hotspot.link,
        hotspot.thumbnailUrl,
        response.customization,
      );
    });

    setViewer(viewer);
  };

  return (
    <main className="bg-slate-900">
      <Transition
        show={query.isLoading === false && query.isError === false}
        enter="transition-all duration-1000"
        enterFrom="-translate-y-full opacity-0"
        enterTo="translate-y-0 opacity-100 shadow-xl"
        as={Fragment}
      >
        <header className="absolute left-0 right-0 top-0 z-50 flex items-stretch bg-slate-900/80 text-center">
          <div className="flex-1 py-2.5 font-medium text-white shadow">
            <h1>{query.data?.data.title}</h1>
          </div>
          <div className="flex items-stretch">
            <button
              onClick={() => setIsRotating(!isRotating)}
              className="flex h-full items-center justify-center bg-white/10   px-4 py-2 text-lg text-gray-200 focus:outline-none"
              title={isRotating ? 'Vypnout automatickou rotaci' : 'Zapnout automatickou rotaci'}
            >
              <FontAwesomeIcon icon="sync" />
            </button>
          </div>
        </header>
      </Transition>
      <div className="relative h-screen w-screen" id="panorama-canvas">
        {query.isLoading && (
          <div className="flex h-full w-full items-center justify-center">
            <Spinner className="h-10 text-white" />
          </div>
        )}
        {query.isError && (
          <div className="flex h-full w-full items-center justify-center">
            <p className="text-4xl font-bold tracking-wider text-slate-100">
              {query.error.response?.data ?? 'Panorama se nepodařilo načíst'}
            </p>
          </div>
        )}
      </div>
    </main>
  );
};
