
import { CubeMap } from '../../types';
import { GetForNewPanoramaResponse } from '../../types/api/panoramas';

export type NewPanoramaAction =
  | FetchAction
  | FetchSuccessAction
  | FetchErrorAction
  // ---
  | SavePanoramaAction
  | SavePanoramaSucessAction
  | SavePanoramaErrorAction
  // --
  | FileUploadedAction
  | CubemapGeneratedAction
  | ProgressAction
  | UploadProgressAction;

export type FetchAction = {
  type: 'PANORAMA.NEW.FETCH';
};

export type FetchSuccessAction = {
  type: 'PANORAMA.NEW.FETCH.SUCCESS';
  payload: GetForNewPanoramaResponse;
};

export type FetchErrorAction = { type: 'PANORAMA.NEW.FETCH.ERROR'; payload: { message: string } };

export type SavePanoramaAction = {
  type: 'PANORAMA.NEW.SAVE';
  payload: {
    title: string;
    customer: string;
    expireDate: Date;
    enabled: boolean;
  };
};

export type SavePanoramaSucessAction = {
  type: 'PANORAMA.NEW.SAVE.SUCCESS';
};

export type SavePanoramaErrorAction = {
  type: 'PANORAMA.NEW.SAVE.ERROR';
};

export type FileUploadedAction = {
  type: 'PANORAMA.NEW.FILE_UPLOADED';
  payload: {
    file: File;
  };
};

export type CubemapGeneratedAction = {
  type: 'PANORAMA.NEW.CUBEMAP_GENERATED';
  payload: {
    cubemap: CubeMap;
  };
};

export type ProgressAction = {
  type: 'PANORAMA.NEW.PROGRESS';
  payload: {
    progress: number;
  };
};

export type UploadProgressAction = {
  type: 'PANORAMA.NEW.UPLOAD_PROGRESS';
  payload: {
    progress: number;
  };
};

// ---

export const fetch = (): NewPanoramaAction => ({
  type: 'PANORAMA.NEW.FETCH',
});

export const fetchSuccess = (data: GetForNewPanoramaResponse): NewPanoramaAction => ({
  type: 'PANORAMA.NEW.FETCH.SUCCESS',
  payload: data,
});

export const fetchError = (message: string): NewPanoramaAction => ({
  type: 'PANORAMA.NEW.FETCH.ERROR',
  payload: {
    message: message,
  },
});

export const savePanorama = (
  title: string,
  customer: string,
  enabled: boolean,
  expireDate: Date,
): NewPanoramaAction => ({
  type: 'PANORAMA.NEW.SAVE',
  payload: {
    title,
    customer,
    enabled,
    expireDate,
  },
});

export const savePanoramaSucess = (): NewPanoramaAction => ({
  type: 'PANORAMA.NEW.SAVE.SUCCESS',
});

export const savePanoramaError = (): NewPanoramaAction => ({
  type: 'PANORAMA.NEW.SAVE.ERROR',
});

export const fileUploaded = (file: File): NewPanoramaAction => ({
  type: 'PANORAMA.NEW.FILE_UPLOADED',
  payload: {
    file,
  },
});

export const cubemapGenerated = (cubemap: CubeMap): NewPanoramaAction => ({
  type: 'PANORAMA.NEW.CUBEMAP_GENERATED',
  payload: {
    cubemap,
  },
});

export const panoramaProgress = (progress: number): ProgressAction => ({
  type: 'PANORAMA.NEW.PROGRESS',
  payload: {
    progress,
  },
});

export const uploadProgress = (progress: number): UploadProgressAction => ({
  type: 'PANORAMA.NEW.UPLOAD_PROGRESS',
  payload: {
    progress,
  },
});
