import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { StoreState } from '../redux/reducers';
import { Logo } from './atoms';

const adminNavigation: Navigation[] = [
  { icon: 'home', title: 'Domů', to: '/', exact: true },
  { icon: 'users', title: 'Zákazníci', to: '/zakaznici' },
  { icon: 'user', title: 'Účet', to: '/ucet' },
  { icon: 'sign-out-alt', title: 'Odhlásit', to: '/ucet/odhlasit' },
];
const customerNavigation: Navigation[] = [
  { icon: 'home', title: 'Domů', to: '/', exact: true },
  { icon: 'user', title: 'Účet', to: '/ucet' },
  { icon: 'sign-out-alt', title: 'Odhlásit', to: '/ucet/odhlasit' },
];

type Navigation = {
  title: string;
  to: string;
  icon: IconProp;
  exact?: boolean;
};

type Props = {
  children: ReactNode;
};

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme['slate-900']};
  padding: 0.5rem 2rem;
`;

export function Layout({ children }: Props) {
  const email = useSelector((state: StoreState) => state.account.email);
  const role = useSelector((state: StoreState) => state.account.role);

  const navigation = role === 'Customer' ? customerNavigation : adminNavigation;

  return (
    <>
      <Header className="relative z-[40] ">
        <div className="flex items-center justify-center gap-4">
          <Logo />
          <div className="text-lg font-bold uppercase tracking-wide text-gray-200">FLYPANO</div>
        </div>
        <div className="flex items-center gap-8">
          <div className="text-sm tracking-wide text-slate-200">{email}</div>
        </div>
      </Header>
      <div className="relative flex h-screen items-stretch whitespace-nowrap pt-[44px]">
        <aside className="relative z-[700] h-full border-t border-slate-700 bg-slate-900 px-4 py-4 text-slate-300 shadow-xl">
          <nav className="space-y-2">
            {navigation.map((item) => (
              <NavLink
                key={item.title}
                to={item.to}
                className="block space-x-4 rounded-md px-10 py-3 transition duration-300 hover:bg-slate-700 hover:text-white hover:shadow hover:shadow-slate-700/50"
                activeClassName="bg-gradient-to-r from-blue-500 to-blue-600 text-slate-100 shadow-inner"
                exact={item.exact}
              >
                <FontAwesomeIcon icon={item.icon} className="-ml-5" />
                <span>{item.title}</span>
              </NavLink>
            ))}
          </nav>
        </aside>
        <div className="flex-grow overflow-y-auto text-black">{children}</div>
      </div>
      <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black/[0.90] p-8 md:hidden">
        <div className="rounded-lg bg-white p-8 text-center shadow-lg">
          <h2 className="mb-2 text-2xl font-bold text-gray-900">Nepodporované rozlišení</h2>
          <p className="text-gray-500">
            Rozlišení pod 768px není v tento moment podporované, pro zobrazení aplikace vyzkoušejte zobrazení na
            počítači
          </p>
        </div>
      </div>
    </>
  );
}
