import { createRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DeletedGalleryImage, GalleryImageState, UntouchedGalleryImage } from '../../redux/reducers/panorama.edit';
import { Guid } from '../../types/api';
import { Button, FileInput, GalleryInput, Input, Label } from '../atoms';
import { InputField, Modal } from '../molecules';

type Props = {
  isOpen: boolean;
  hotspot: ModalData;
  onClose: () => void;
  onConfirm: (data: ConfirmData) => void;
  isConfirming: boolean;
};

type ModalData = {
  title: string;
  description: string;
  thumbnailUrl?: string;
  thumbnailName?: string;
  matterportLink: string;
  youtubeLink: string;
  links: Link[];
  galleryImages: {
    id: Guid;
    thumbnailUrl: string;
  }[];
};

type Link = {
  label: string;
  href: string;
};

type ConfirmData = {
  title: string;
  description: string;
  matterportLink: string;
  youtubeLink: string;
  links: Link[];
  thumbnailEdit: boolean;
  thumbnail: File | undefined;
  galleryImages: GalleryImageState[];
};

type FormState = {
  title: string;
  description: string;
  matterportLink: string;
  youtubeLink: string;
};

export const MainHotspotModal = ({ isOpen, hotspot, onClose, onConfirm, isConfirming }: Props) => {
  const [thumbnailName, setThumbnailName] = useState(hotspot.thumbnailName);
  const [thumbnailEdit, setThumbnailEdit] = useState(false);
  const [thumbnail, setThumbnail] = useState<File>();
  const [links, setLinks] = useState<Link[]>([]);
  const [galleryImages, setGalleryImages] = useState<GalleryImageState[]>([]);

  const confirm = (formState: FormState) => {
    onConfirm({
      title: formState.title,
      description: formState.description,
      matterportLink: formState.matterportLink,
      youtubeLink: formState.youtubeLink,
      thumbnailEdit,
      thumbnail,
      galleryImages,
      links,
    });
  };

  useEffect(() => {
    if (isOpen) {
      reset();
      setThumbnailName(hotspot.thumbnailName);
      setThumbnailEdit(false);
      setThumbnail(undefined);
      setLinks(hotspot.links);
      setGalleryImages(
        hotspot.galleryImages.map((image) => ({
          state: 'Untouched',
          id: image.id,
          thumbnailUrl: image.thumbnailUrl,
        })),
      );
    }
  }, [hotspot, isOpen]);

  const onMoveGalleryFileLeft = (index: number) => {
    if (index <= 0 || index >= galleryImages.length) {
      // Invalid index or item already at the leftmost position
      return;
    }

    setGalleryImages((prev) => [...prev.slice(0, index - 1), prev[index], prev[index - 1], ...prev.slice(index + 1)]);
  };

  const onMoveGalleryFileRight = (index: number) => {
    if (index < 0 || index >= galleryImages.length - 1) {
      // Invalid index or item already at the rightmost position
      return;
    }

    setGalleryImages((prev) => [...prev.slice(0, index), prev[index + 1], prev[index], ...prev.slice(index + 2)]);
  };

  const onDeleteGalleryFile = (index: number) => {
    if (index < 0 || index > galleryImages.length) {
      // Invalid index
      return;
    }

    const image = galleryImages[index];
    if (image.state === 'New') {
      setGalleryImages((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
    } else {
      const deletedImage: DeletedGalleryImage = { state: 'Deleted', id: image.id };
      setGalleryImages((prev) => [...prev.slice(0, index), deletedImage, ...prev.slice(index + 1)]);
    }
  };

  const onAddLink = () => {
    setLinks([...links, { label: '', href: '' }]);
  };

  const formRef = createRef<HTMLFormElement>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<FormState>({ mode: 'onBlur', shouldFocusError: false });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Hlavní hotspot"
      body={
        <form className="space-y-4" ref={formRef} onSubmit={handleSubmit((data) => confirm(data))}>
          <InputField
            type="text"
            label="Název hotspotu"
            register={register('title', {
              required: 'Název hotspotu musí být vyplněn',
            })}
            error={errors.title}
          />
          <InputField
            type="rich-editor"
            label="Popis hotspotu"
            register={register('description', {
              required: 'Popis hotspotu musí být vyplněn',
            })}
            error={errors.description}
            setValue={setValue}
          />
          <div>
            <Label htmlFor="main-hotspot-modal-photo">Úvodní fotografie</Label>
            <FileInput
              name="main-hotspot-modal-photo"
              id="main-hotspot-modal-photo"
              icon="image"
              onChange={(file) => {
                setThumbnailName(file.name);
                setThumbnail(file);
                setThumbnailEdit(true);
              }}
              onRemove={() => {
                setThumbnailName(undefined);
                setThumbnail(undefined);
                setThumbnailEdit(true);
              }}
              fileName={thumbnailName}
            />
          </div>
          <InputField
            type="text"
            label="Odkaz na matterport"
            register={register('matterportLink', {})}
            error={errors.matterportLink}
          />
          <InputField
            type="text"
            label="Odkaz na youtube video"
            register={register('youtubeLink', {})}
            error={errors.youtubeLink}
          />
          <div>
            <Label htmlFor="main-hotspot-modal-links">Další odkazy</Label>
            {links.length > 0 && (
              <>
                <div className="mb-1.5 rounded border bg-slate-50 px-3 py-2">
                  {links.map((link, index) => (
                    <div className="flex items-end gap-2 py-1" key={index}>
                      <div className="w-26">
                        <Label htmlFor={`main-hotspot-modal-links-${index}-name`} size="tiny">
                          Popis odkazu
                        </Label>
                        <Input
                          name={`main-hotspot-modal-links-${index}`}
                          id={`main-hotspot-modal-links-${index}`}
                          type="text"
                          size="tiny"
                          value={link.label}
                          onChange={(value) => {
                            const updatedLinks = [...links];
                            updatedLinks[index].label = value;
                            setLinks(updatedLinks);
                          }}
                        />
                      </div>
                      <div className="flex-grow">
                        <Label htmlFor={`main-hotspot-modal-links-${index}-href`} size="tiny">
                          Adresa odkazu
                        </Label>
                        <Input
                          name={`main-hotspot-modal-links-${index}`}
                          id={`main-hotspot-modal-links-${index}`}
                          type="text"
                          size="tiny"
                          value={link.href}
                          onChange={(value) => {
                            const updatedLinks = [...links];
                            updatedLinks[index].href = value;
                            setLinks(updatedLinks);
                          }}
                        />
                      </div>
                      <div>
                        <Button
                          variant="transparent"
                          size="smallSquare"
                          icon="trash"
                          onClick={() => {
                            const updatedLinks = [...links];
                            updatedLinks.splice(index, 1);
                            setLinks(updatedLinks);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <p className="mb-3 pl-1 text-xs font-light text-slate-600">
                  Adresy odkazů zadávejte jako absolutní cesty (např: https://seznam.cz){' '}
                </p>
              </>
            )}
            <Button variant="gray" size="tiny" icon="plus" onClick={onAddLink}>
              Přidat odkaz
            </Button>
          </div>
          <div>
            <Label htmlFor="main-hotspot-modal-gallery">Galerie</Label>
            <GalleryInput
              files={galleryImages.filter(
                (image: GalleryImageState): image is UntouchedGalleryImage => image.state !== 'Deleted',
              )}
              onAddFiles={(files) => {
                const updatedFiles: GalleryImageState[] = files.map((file) => ({
                  state: 'New',
                  file,
                  thumbnailUrl: URL.createObjectURL(file),
                }));
                setGalleryImages((prevFiles) => [...prevFiles, ...updatedFiles]);
              }}
              onMoveLeft={onMoveGalleryFileLeft}
              onMoveRight={onMoveGalleryFileRight}
              onDelete={onDeleteGalleryFile}
            />
          </div>
        </form>
      }
      footer={
        <>
          <Button variant="gray" onClick={onClose} size="small">
            Zrušit
          </Button>
          <Button
            variant="blue"
            onClick={() => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
            size="small"
            isLoading={isConfirming}
          >
            Potvrdit
          </Button>
        </>
      }
    />
  );
};
