import { AxiosResponse } from 'axios';
import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { api } from '../../api';
import { Endpoints } from '../../api/endpoints';
import {
  CreateInfoHotspotResponse,
  CreateMainHotspotResponse,
  GetPanoramaDetailResponse,
  UpdateInfoHotspotResponse,
  UpdateMainHotspotResponse,
  UpdatePanoramaRequest,
} from '../../types/api/panoramas';

import { toast } from 'react-toastify';
import {
  createViewerAndScene,
  renderInfoHotspot,
  renderInfoHotspotToElement,
  renderMainHotspot,
  renderMainHotspotToElement,
} from '../../functions/panorama';
import { InfoHotspot } from '../../types';
import { Scene } from '../../types/marzipano-types';
import {
  ConfirmMoveHotspotAction,
  FetchAction,
  SavePanoramaAction,
  canvasLoaded,
  fetch,
  fetchError,
  fetchSuccess,
} from '../actions/panorama.edit';
import {
  CreateInfoHotspotAction,
  DeleteInfoHotspotAction,
  UpdateInfoHotspotAction,
  createInfoHotspotSucess,
  deleteInfoHotspotSuccess,
  updateInfoHotspotSuccess,
} from '../actions/panorama.edit.infoHotspot';
import {
  CreateMainHotspotAction,
  DeleteMainHotspotAction,
  UpdateMainHotspotAction,
  createMainHotspotSucess,
  deleteMainHotspotSuccess,
  updateMainHotspotError,
  updateMainHotspotSuccess,
} from '../actions/panorama.edit.mainhotspot';
import {
  CustomizationState,
  DeletedGalleryImage,
  GalleryImageState,
  MainHotspotState,
  MovingHotspotState,
  NewGalleryImage,
  PanoramaEditState,
  UntouchedGalleryImage,
} from '../reducers/panorama.edit';
import { selectState } from '../selectState';

export function* fetchSaga(action: FetchAction) {
  try {
    const response: AxiosResponse<GetPanoramaDetailResponse> = yield call(
      api().get,
      Endpoints.Panoramas.Detail(action.payload.id),
    );

    yield put(fetchSuccess(response.data));
    yield delay(500);

    const { mainHotspot, infoHotspots, customization, initialYaw, initialPitch } = response.data;
    const { viewer, scene } = createViewerAndScene(+initialYaw, +initialPitch, action.payload.id);

    for (let index = 0; index < infoHotspots.length; index++) {
      const hotspot = infoHotspots[index];
      const hotspotRef = renderInfoHotspot(
        scene,
        +hotspot.yaw,
        +hotspot.pitch,
        hotspot.title,
        hotspot.description,
        hotspot.link,
        hotspot.thumbnailUrl,
        customization,
      );

      yield put(
        createInfoHotspotSucess(
          hotspot.id,
          hotspot.title,
          +hotspot.yaw,
          +hotspot.pitch,
          hotspot.description,
          hotspot.thumbnailName,
          hotspot.thumbnailUrl,
          hotspot.link,
          hotspotRef,
        ),
      );
    }

    if (mainHotspot) {
      const hotspotRef = renderMainHotspot(
        scene,
        +mainHotspot.yaw,
        +mainHotspot.pitch,
        mainHotspot.title,
        mainHotspot.description,
        mainHotspot.matterportLink,
        mainHotspot.youtubeLink,
        mainHotspot.links,
        mainHotspot.thumbnailUrl,
        mainHotspot.galleryImages,
        {
          iconUrl: customization.iconUrl,
          background: customization.mainHotspotBackground,
          foreground: customization.mainHotspotForeground,
        },
      );

      yield put(
        createMainHotspotSucess(
          mainHotspot.id,
          mainHotspot.title,
          +mainHotspot.yaw,
          +mainHotspot.pitch,
          mainHotspot.description,
          mainHotspot.thumbnailName,
          mainHotspot.thumbnailUrl,
          mainHotspot.matterportLink,
          mainHotspot.youtubeLink,
          mainHotspot.links,
          mainHotspot.galleryImages,
          hotspotRef,
        ),
      );
    }
    yield put(canvasLoaded(scene, viewer));
  } catch (error: any) {
    yield put(fetchError(error.response ? error.response : 'Nastala neznámá chyba'));
  }
}

export function* saveSaga(action: SavePanoramaAction) {
  const state: PanoramaEditState = yield selectState((state) => state.panoramaEdit);

  const request: UpdatePanoramaRequest = {
    enabled: action.payload.enabled,
    initialYaw: action.payload.yaw.toString(),
    initialPitch: action.payload.pitch.toString(),
    expireDate: action.payload.expireDate,
  };

  try {
    yield call(api().put, Endpoints.Panoramas.Index + `/${state.id}`, request);

    toast(`Panorama ${state.title} uloženo`, {
      type: 'success',
    });
    yield put(fetch(state.id));
  } catch (error) {
    toast('Nepodařilo se uložit panorama', {
      type: 'error',
    });
  }
}

export function* createInfoHotspotSaga(action: CreateInfoHotspotAction) {
  const panoramaId: string = yield selectState((state) => state.panoramaEdit.id);
  const customization: CustomizationState = yield selectState((state) => state.panoramaEdit.customization);
  const scene: Scene = yield selectState((state) => state.panoramaEdit.scene);

  const formData = new FormData();
  formData.append('panoramaId', panoramaId);
  formData.append('title', action.payload.title);
  formData.append('description', action.payload.description ?? '');
  formData.append('link', action.payload.link ?? '');
  formData.append('yaw', action.payload.yaw.toString());
  formData.append('pitch', action.payload.pitch.toString());

  action.payload.thumbnail && formData.append('thumbnail', action.payload.thumbnail);

  try {
    const response: AxiosResponse<CreateInfoHotspotResponse> = yield call(
      api().post,
      Endpoints.InfoHotspots.Create,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );

    const hotspotRef = renderInfoHotspot(
      scene,
      action.payload.yaw,
      action.payload.pitch,
      action.payload.title,
      action.payload.description,
      action.payload.link,
      response.data.thumbnailUrl,
      customization,
    );

    yield put(
      createInfoHotspotSucess(
        response.data.id,
        action.payload.title,
        action.payload.yaw,
        action.payload.pitch,
        action.payload.description,
        response.data.thumbnailName,
        response.data.thumbnailName,
        action.payload.link,
        hotspotRef,
      ),
    );

    toast(`Hostpot ${action.payload.title} vytvořen`, {
      type: 'info',
    });
  } catch (error) {
    toast('Nepodařilo se uložit hotspot', {
      type: 'error',
    });
  }
}

export function* deleteInfoHotspotSaga(action: DeleteInfoHotspotAction) {
  const hotspots: InfoHotspot[] = yield selectState((state) => state.panoramaEdit.infoHotspots);
  const hotspot = hotspots.find((hotspot) => hotspot.id === action.payload.id);

  try {
    if (hotspot) {
      yield call(api().delete, Endpoints.InfoHotspots.Delete(action.payload.id));

      hotspot.hotspotRef?.destroy();

      yield put(deleteInfoHotspotSuccess(action.payload.id));

      toast(`Hostpot ${hotspot.title} odstraněn`, {
        type: 'info',
      });
    }
  } catch (error) {
    toast('Nepodařilo se smazat hotspot', {
      type: 'error',
    });
  }
}

export function* updateInfoHotspotSaga(action: UpdateInfoHotspotAction) {
  const hotspots: InfoHotspot[] = yield selectState((state) => state.panoramaEdit.infoHotspots);
  const customization: CustomizationState = yield selectState((state) => state.panoramaEdit.customization);
  const hotspot = hotspots.find((hotspot) => hotspot.id === action.payload.id);

  if (!hotspot) return;

  const formData = new FormData();
  formData.append('title', action.payload.title);
  formData.append('description', action.payload.description ?? '');
  formData.append('link', action.payload.link ?? '');
  formData.append('yaw', hotspot.yaw.toString());
  formData.append('pitch', hotspot.pitch.toString());

  action.payload.thumbnailEdit && formData.append('thumbnailEdit', true.toString());
  action.payload.thumbnail && formData.append('thumbnail', action.payload.thumbnail);

  try {
    const response: AxiosResponse<UpdateInfoHotspotResponse> = yield call(
      api().put,
      Endpoints.InfoHotspots.Update(hotspot.id),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );

    renderInfoHotspotToElement(
      action.payload.title,
      action.payload.description,
      action.payload.link,
      response.data.thumbnailUrl,
      customization,
      hotspot.hotspotRef?.domElement()!,
    );

    yield put(
      updateInfoHotspotSuccess(
        action.payload.id,
        action.payload.title,
        hotspot.yaw,
        hotspot.pitch,
        action.payload.description,
        response.data.thumbnailName,
        response.data.thumbnailUrl,
        action.payload.link,
      ),
    );

    toast(`Hostpot ${action.payload.title} upraven`, {
      type: 'info',
    });
  } catch (error: any) {
    toast('Nepodařilo se uložit hlavní hotspot', {
      type: 'error',
    });
  }
}

export function* createMainHotspotSaga(action: CreateMainHotspotAction) {
  const panoramaId: string = yield selectState((state) => state.panoramaEdit.id);
  const customization: CustomizationState = yield selectState((state) => state.panoramaEdit.customization);
  const scene: Scene = yield selectState((state) => state.panoramaEdit.scene);

  const formData = new FormData();
  formData.append('panoramaId', panoramaId);
  formData.append('title', action.payload.title);
  formData.append('description', action.payload.description);
  formData.append('yaw', action.payload.yaw.toString());
  formData.append('pitch', action.payload.pitch.toString());
  formData.append('youtubeLink', action.payload.youtubeLink ?? '');
  formData.append('matterportLink', action.payload.matterportLink ?? '');

  action.payload.thumbnail && formData.append('thumbnail', action.payload.thumbnail);

  action.payload.links.forEach((link, index) => {
    formData.append(`links[${index}][label]`, link.label);
    formData.append(`links[${index}][href]`, link.href);
  });

  action.payload.galleryImages
    .filter((image: GalleryImageState): image is NewGalleryImage => image.state === 'New')
    .forEach((image, index) => {
      formData.append('galleryImages[' + index + '].File', image.file);
      formData.append('galleryImages[' + index + '].Index', index.toString());
    });

  try {
    const response: AxiosResponse<CreateMainHotspotResponse> = yield call(
      api().post,
      Endpoints.MainHotspots.Create,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );

    const hotspotRef = renderMainHotspot(
      scene,
      action.payload.yaw,
      action.payload.pitch,
      action.payload.title,
      action.payload.description,
      action.payload.matterportLink,
      action.payload.youtubeLink,
      action.payload.links,
      response.data.thumbnailUrl,
      response.data.galleryImages,
      {
        iconUrl: customization.iconUrl,
        background: customization.mainHotspotBackground,
        foreground: customization.mainHotspotForeground,
      },
    );

    yield put(
      createMainHotspotSucess(
        response.data.id,
        action.payload.title,
        action.payload.yaw,
        action.payload.pitch,
        action.payload.description,
        response.data.thumbnailName,
        response.data.thumbnailUrl,
        action.payload.matterportLink,
        action.payload.youtubeLink,
        action.payload.links,
        response.data.galleryImages,
        hotspotRef,
      ),
    );

    toast(`Hlavní hostpot ${action.payload.title} vytvořen`, {
      type: 'info',
    });
  } catch (error: any) {
    toast('Nepodařilo se uložit hlavní hotspot', {
      type: 'error',
    });
  }
}

export function* deleteMainHotspotSaga(action: DeleteMainHotspotAction) {
  const hotspot: MainHotspotState = yield selectState((state) => state.panoramaEdit.mainHotspot);

  try {
    if (hotspot) {
      yield call(api().delete, Endpoints.MainHotspots.Delete(hotspot.id));
      hotspot.hotspotRef?.destroy();
      yield put(deleteMainHotspotSuccess());

      toast(`Hlavní hostpot ${hotspot.title} odstraněn`, {
        type: 'info',
      });
    }
  } catch (error) {
    toast('Nepodařilo se smazat hlavní hotspot', {
      type: 'error',
    });
  }
}

export function* updateMainHotspotSaga(action: UpdateMainHotspotAction) {
  const hotspot: MainHotspotState = yield selectState((state) => state.panoramaEdit.mainHotspot);
  const customization: CustomizationState = yield selectState((state) => state.panoramaEdit.customization);

  if (!hotspot) return;

  const formData = new FormData();
  formData.append('title', action.payload.title);
  formData.append('description', action.payload.description ?? '');
  formData.append('youtubeLink', action.payload.youtubeLink ?? '');
  formData.append('matterportLink', action.payload.matterportLink ?? '');
  formData.append('yaw', hotspot.yaw.toString());
  formData.append('pitch', hotspot.pitch.toString());

  action.payload.thumbnailEdit && formData.append('thumbnailEdit', true.toString());
  action.payload.thumbnail && formData.append('thumbnail', action.payload.thumbnail);

  action.payload.links.forEach((link, index) => {
    formData.append(`links[${index}][label]`, link.label);
    formData.append(`links[${index}][href]`, link.href);
  });

  action.payload.galleryImages
    .filter((image: GalleryImageState): image is DeletedGalleryImage => image.state === 'Deleted')
    .forEach((image, index) => formData.append(`DeletedGaleryItems[${index}]`, image.id));

  const galleryImagesWithIndex = action.payload.galleryImages.map((image, index) => ({ ...image, index }));

  galleryImagesWithIndex
    .filter((image: GalleryImageState): image is NewGalleryImage => image.state === 'New')
    .forEach((image, index) => {
      formData.append(`NewGaleryItems[${index}].File`, (image as NewGalleryImage).file);
      formData.append(`NewGaleryItems[${index}].Index`, image.index.toString());
    });

  galleryImagesWithIndex
    .filter((image: GalleryImageState): image is UntouchedGalleryImage => image.state === 'Untouched')
    .forEach((image, index) => {
      formData.append(`UpdatedGaleryItems[${index}].Id`, (image as UntouchedGalleryImage).id);
      formData.append(`UpdatedGaleryItems[${index}].Index`, image.index.toString());
    });

  try {
    const response: AxiosResponse<UpdateMainHotspotResponse> = yield call(
      api().put,
      Endpoints.MainHotspots.Update(hotspot.id),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );

    renderMainHotspotToElement(
      action.payload.title,
      action.payload.description,
      action.payload.matterportLink,
      action.payload.youtubeLink,
      action.payload.links,
      response.data.thumbnailUrl,
      response.data.galleryImages,
      {
        iconUrl: customization.iconUrl,
        background: customization.mainHotspotBackground,
        foreground: customization.mainHotspotForeground,
      },
      hotspot.hotspotRef?.domElement()!,
    );

    yield put(
      updateMainHotspotSuccess(
        action.payload.title,
        hotspot.yaw,
        hotspot.pitch,
        action.payload.description,
        response.data.thumbnailName,
        response.data.thumbnailUrl,
        action.payload.youtubeLink,
        action.payload.matterportLink,
        response.data.galleryImages,
      ),
    );

    toast(`Hlavní hostpot ${action.payload.title} upraven`, {
      type: 'info',
    });
  } catch (error) {
    yield put(updateMainHotspotError());

    toast('Nepodařilo se uložit hlavní hotspot', {
      type: 'error',
    });
  }
}

export function* moveHotspotSaga(action: ConfirmMoveHotspotAction) {
  const movingHotspot: MovingHotspotState = yield selectState((state) => state.panoramaEdit.movingHotspot);

  if (movingHotspot.isMain) {
    const hotspot: MainHotspotState = yield selectState((state) => state.panoramaEdit.mainHotspot);

    if (!hotspot) return;

    const formData = new FormData();
    formData.append('title', hotspot.title);
    formData.append('description', hotspot.description ?? '');
    formData.append('youtubeLink', hotspot.youtubeLink ?? '');
    formData.append('matterportLink', hotspot.matterportLink ?? '');
    formData.append('yaw', action.payload.yaw.toString());
    formData.append('pitch', action.payload.pitch.toString());

    try {
      const response: AxiosResponse<UpdateMainHotspotResponse> = yield call(
        api().put,
        Endpoints.MainHotspots.Update(hotspot.id),
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      hotspot.hotspotRef?.setPosition({ yaw: action.payload.yaw, pitch: action.payload.pitch });

      yield put(
        updateMainHotspotSuccess(
          formData.get('title') as string,
          action.payload.yaw,
          action.payload.pitch,
          formData.get('description') as string,
          response.data.thumbnailName,
          response.data.thumbnailUrl,
          formData.get('matterportLink') as string,
          formData.get('youtubeLink') as string,
          response.data.galleryImages,
        ),
      );

      toast(`Hlavní hostpot ${formData['title']} přesunut`, {
        type: 'info',
      });
    } catch (error) {
      toast('Nepodařilo se přesunout hlavní hotspot', {
        type: 'error',
      });
    }
  }

  if (movingHotspot.isMain === false) {
    const hotspots: InfoHotspot[] = yield selectState((state) => state.panoramaEdit.infoHotspots);
    const hotspot = hotspots.find((hotspot) => hotspot.id === movingHotspot.id);

    if (!hotspot) return;

    const formData = new FormData();
    formData.append('title', hotspot.title);
    formData.append('description', hotspot.description ?? '');
    formData.append('yaw', action.payload.yaw.toString());
    formData.append('pitch', action.payload.pitch.toString());
    formData.append('link', hotspot.link ?? '');

    try {
      const response: AxiosResponse<UpdateInfoHotspotResponse> = yield call(
        api().put,
        Endpoints.InfoHotspots.Update(hotspot.id),
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      hotspot.hotspotRef?.setPosition({ yaw: action.payload.yaw, pitch: action.payload.pitch });

      yield put(
        updateInfoHotspotSuccess(
          hotspot.id,
          hotspot.title,
          action.payload.yaw,
          action.payload.pitch,
          hotspot.description,
          response.data.thumbnailName,
          response.data.thumbnailUrl,
          hotspot.link ?? '',
        ),
      );

      toast(`Hlavní hostpot ${hotspot.title} přesunut`, {
        type: 'info',
      });
    } catch (error) {
      toast('Nepodařilo se přesunout hlavní hotspot', {
        type: 'error',
      });
    }
  }
}

// ---

export function* watchFetchSaga() {
  yield takeEvery('PANORAMA.EDIT.FETCH', fetchSaga);
}

export function* watchSaveSaga() {
  yield takeEvery('PANORAMA.EDIT.SAVE', saveSaga);
}

export function* watchCreateInfoHotspotSaga() {
  yield takeEvery('PANORAMA.EDIT.INFO_HOTSPOT.CREATE', createInfoHotspotSaga);
}

export function* watchDeleteInfoHotspotSaga() {
  yield takeEvery('PANORAMA.EDIT.INFO_HOTSPOT.DELETE', deleteInfoHotspotSaga);
}

export function* watchUpdateInfoHotspotSaga() {
  yield takeEvery('PANORAMA.EDIT.INFO_HOTSPOT.UPDATE', updateInfoHotspotSaga);
}

export function* watchCreateMainHotspotSaga() {
  yield takeEvery('PANORAMA.EDIT.MAIN_HOTSPOT.CREATE', createMainHotspotSaga);
}

export function* watchDeleteMainHotspotSaga() {
  yield takeEvery('PANORAMA.EDIT.MAIN_HOTSPOT.DELETE', deleteMainHotspotSaga);
}

export function* watchUpdateMainHotspotSaga() {
  yield takeEvery('PANORAMA.EDIT.MAIN_HOTSPOT.UPDATE', updateMainHotspotSaga);
}

export function* watchConfirmMoveHotspotSaga() {
  yield takeEvery('PANORAMA.EDIT.MOVE_HOTSPOT.CONFIRM', moveHotspotSaga);
}

// ---

const panoramaEditSagas = [
  watchFetchSaga(),
  watchSaveSaga(),
  watchCreateInfoHotspotSaga(),
  watchDeleteInfoHotspotSaga(),
  watchUpdateInfoHotspotSaga(),
  watchCreateMainHotspotSaga(),
  watchDeleteMainHotspotSaga(),
  watchUpdateMainHotspotSaga(),
  watchConfirmMoveHotspotSaga(),
];

export default panoramaEditSagas;
