import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PrivateRoute } from './components';
import { Layout } from './components/Layout';
import {
  AccountPage,
  ConfirmAccountPage,
  CustomersPage,
  EditCustomerPage,
  EditPanoramaPage,
  HomePage,
  LoginPage,
  LogoutPage,
  NewCustomerPage,
  NewPanoramaPage,
} from './pages';
import { PanoramaOutputPage } from './pages/PanoramaOutputPage';
import { loginSuccess } from './redux/actions/account';
import { StoreState } from './redux/reducers/index';

const queryClient = new QueryClient();

export default function App() {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state: StoreState) => state.account.isLoggedIn);

  const token = localStorage.getItem('token');
  if (token && !isLoggedIn) {
    dispatch(loginSuccess(token));
  }
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route path="/potvrdit-ucet/:id" exact component={ConfirmAccountPage} />
          <Route path="/prihlaseni" exact component={LoginPage} />
          <Route path="/panorama/:id/output" component={PanoramaOutputPage} />

          <Layout>
            <PrivateRoute path="/" exact component={<HomePage />} />
            <PrivateRoute path="/panorama/nove" exact component={<NewPanoramaPage />} />
            <PrivateRoute path="/panorama/upravit/:id/" exact component={<EditPanoramaPage />} />

            <PrivateRoute path="/zakaznici/" exact component={<CustomersPage />} />
            <PrivateRoute path="/zakaznici/novy" exact component={<NewCustomerPage />} />
            <PrivateRoute path="/zakaznici/upravit/:id/" exact component={<EditCustomerPage />} />

            <PrivateRoute path="/ucet/" exact component={<AccountPage />} />
            <PrivateRoute path="/ucet/odhlasit" exact component={<LogoutPage />} />
          </Layout>
        </Switch>
        <ReactQueryDevtools initialIsOpen={false} />
        <ToastContainer autoClose={2500} />
      </QueryClientProvider>
    </>
  );
}
