import axios, { AxiosError, AxiosInstance } from 'axios';
import { logout } from '../redux/actions/account';
import store from '../redux/store';

export const url = process.env.NODE_ENV === 'development' ? 'https://localhost:7125/' : '/';
export { toastError } from './toastError';

export function api(): AxiosInstance {
  const state = store.getState();

  const headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
  };

  if (state.account.token) {
    headers.Authorization = `Bearer ${state.account.token}`;
  }

  const instance = axios.create({
    baseURL: url,
    headers,
  });

  instance.interceptors.response.use(
    (response) => {
      handleDates(response.data);

      if (isIsoDateString(response.data)) response.data = new Date(response.data);

      return response;
    },
    (error: AxiosError) => {
      const { response } = error || { response: null, config: {} };
      const { status } = response || { status: null };

      if (status === 401) {
        store.dispatch(logout());
      }

      return Promise.reject(error);
    },
  );
  instance.interceptors.request.use((request) => {
    return request;
  });

  return instance;
}

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value);
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object') return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = new Date(value);
    else if (typeof value === 'object') handleDates(value);
  }
}
