import { Guid } from '../../types/api';
import { Hotspot as MarzipanoHotspot } from '../../types/marzipano-types';
import { GalleryImageState, HotspotLink } from '../reducers/panorama.edit';

export type MainHotspotAction =
  | OpenMainHotspotModalAction
  | CloseMainHotspotModalAction
  | ConfirmMainHotspotModalAction
  | CancelMainHotspotPlaceAction
  | CreateMainHotspotAction
  | CreateMainHotspotSuccessAction
  | DeleteMainHotspotAction
  | DeleteMainHotspotSuccessAction
  | UpdateMainHotspotAction
  | UpdateMainHotspotSuccessAction
  | UpdateMainHotspotErrorAction;

export type OpenMainHotspotModalAction = {
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.MODAL.OPEN';
  payload:
    | {
        title: string;
        yaw: number;
        pitch: number;
        description: string;
        matterportLink: string | undefined;
        youtubeLink: string | undefined;
        galleryImages: {
          id: Guid;
          thumbnailUrl: string;
        }[];
      }
    | undefined;
};

export type CloseMainHotspotModalAction = {
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.MODAL.CLOSE';
};

export type ConfirmMainHotspotModalAction = {
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.MODAL.CONFIRM';
  payload: {
    title: string;
    description: string;
    matterportLink: string | undefined;
    youtubeLink: string | undefined;
    links: HotspotLink[];
    thumbnailEdit: boolean;
    thumbnail: File | undefined;
    galleryImages: GalleryImageState[];
  };
};

export type CancelMainHotspotPlaceAction = {
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.PLACE.CANCEL';
};

export type CreateMainHotspotAction = {
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.CREATE';
  payload: {
    title: string;
    yaw: number;
    pitch: number;
    description: string;
    matterportLink?: string;
    youtubeLink?: string;
    links: HotspotLink[];
    thumbnail: File | undefined;
    galleryImages: GalleryImageState[];
  };
};

export type CreateMainHotspotSuccessAction = {
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.CREATE.SUCCESS';
  payload: {
    id: Guid;
    title: string;
    yaw: number;
    pitch: number;
    description: string;
    thumbnailName: string | undefined;
    thumbnailUrl: string | undefined;
    matterportLink?: string;
    youtubeLink?: string;
    links: HotspotLink[];
    galleryImages: {
      id: Guid;
      thumbnailUrl: string;
    }[];
    hotspotRef: MarzipanoHotspot;
  };
};

export type DeleteMainHotspotAction = {
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.DELETE';
};

export type DeleteMainHotspotSuccessAction = {
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.DELETE.SUCCESS';
};

export type UpdateMainHotspotAction = {
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.UPDATE';
  payload: {
    title: string;
    description: string;
    youtubeLink: string | undefined;
    matterportLink: string | undefined;
    links: HotspotLink[];
    thumbnailEdit: boolean;
    thumbnail: File | undefined;
    galleryImages: GalleryImageState[];
  };
};

export type UpdateMainHotspotSuccessAction = {
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.UPDATE.SUCCESS';
  payload: {
    title: string;
    yaw: number;
    pitch: number;
    description: string;
    thumbnailName: string | undefined;
    thumbnailUrl: string | undefined;
    youtubeLink: string | undefined;
    matterportLink: string | undefined;
    galleryImages: {
      id: Guid;
      thumbnailUrl: string;
    }[];
  };
};

export type UpdateMainHotspotErrorAction = {
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.UPDATE.ERROR';
  payload: {};
};

export const openMainHotspotModal = (
  data:
    | {
        title: string;
        yaw: number;
        pitch: number;
        description: string;
        matterportLink: string | undefined;
        youtubeLink: string | undefined;
        galleryImages: {
          id: Guid;
          thumbnailUrl: string;
        }[];
      }
    | undefined,
): MainHotspotAction => ({
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.MODAL.OPEN',
  payload: data,
});

export const closeMainHotspotModal = (): MainHotspotAction => ({
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.MODAL.CLOSE',
});

export const confirmMainHotspotModal = (
  title: string,
  description: string,
  youtubeLink: string | undefined,
  matterportLink: string | undefined,
  links: HotspotLink[],
  thumbnailEdit: boolean,
  thumbnail: File | undefined,
  galleryImages: GalleryImageState[],
): MainHotspotAction => ({
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.MODAL.CONFIRM',
  payload: {
    title,
    description,
    youtubeLink,
    matterportLink,
    links,
    thumbnailEdit,
    thumbnail,
    galleryImages,
  },
});

export const createMainHotspot = (
  title: string,
  yaw: number,
  pitch: number,
  description: string,
  youtubeLink: string | undefined,
  matterportLink: string | undefined,
  links: HotspotLink[],
  thumbnail: File | undefined,
  galleryImages: GalleryImageState[],
): MainHotspotAction => ({
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.CREATE',
  payload: {
    title,
    yaw,
    pitch,
    description,
    youtubeLink,
    matterportLink,
    links,
    thumbnail,
    galleryImages,
  },
});

export const createMainHotspotSucess = (
  id: Guid,
  title: string,
  yaw: number,
  pitch: number,
  description: string,
  thumbnailName: string | undefined,
  thumbnailUrl: string | undefined,
  matterportLink: string | undefined,
  youtubeLink: string | undefined,
  links: HotspotLink[],
  galleryImages: {
    id: Guid;
    thumbnailUrl: string;
  }[],
  hotspotRef: MarzipanoHotspot,
): MainHotspotAction => ({
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.CREATE.SUCCESS',
  payload: {
    id,
    title,
    yaw,
    pitch,
    description,
    thumbnailName,
    thumbnailUrl,
    matterportLink,
    youtubeLink,
    links,
    galleryImages,
    hotspotRef,
  },
});

export const deleteMainHotspot = (): MainHotspotAction => ({
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.DELETE',
});

export const deleteMainHotspotSuccess = (): MainHotspotAction => ({
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.DELETE.SUCCESS',
});

export const updateMainHotspot = (
  title: string,
  description: string,
  youtubeLink: string | undefined,
  matterportLink: string | undefined,
  links: HotspotLink[],
  thumbnailEdit: boolean,
  thumbnail: File | undefined,
  galleryImages: GalleryImageState[],
): MainHotspotAction => ({
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.UPDATE',
  payload: {
    title,
    description,
    youtubeLink,
    matterportLink,
    links,
    thumbnailEdit,
    thumbnail,
    galleryImages,
  },
});

export const updateMainHotspotSuccess = (
  title: string,
  yaw: number,
  pitch: number,
  description: string,
  thumbnailName: string | undefined,
  thumbnailUrl: string | undefined,
  youtubeLink: string | undefined,
  matterportLink: string | undefined,
  galleryImages: {
    id: Guid;
    thumbnailUrl: string;
  }[],
): MainHotspotAction => ({
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.UPDATE.SUCCESS',
  payload: {
    title,
    yaw,
    pitch,
    description,
    thumbnailName,
    thumbnailUrl,
    youtubeLink,
    matterportLink,
    galleryImages,
  },
});

export const updateMainHotspotError = (): MainHotspotAction => ({
  type: 'PANORAMA.EDIT.MAIN_HOTSPOT.UPDATE.ERROR',
  payload: {},
});
