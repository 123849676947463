import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from '../../utils';

type Props = {
  name: string;
  id: string;
  value: string;
  onChange: (value: string) => void;
  icon?: IconName;
  options: Option[];
};

type Option = string | { label: string; value: string };

export const Select = ({ name, id, value, options, onChange, icon }: Props) => (
  <div className="group relative shadow-sm">
    <select
      name={name}
      id={id}
      value={value}
      onChange={(e) => onChange(e.currentTarget.value)}
      className={classNames(
        'mt-1 block min-h-[38px] w-full appearance-none rounded-md border border-slate-300 bg-white px-4 py-2 text-sm transition hover:border-blue-500 focus:border-blue-700 focus:outline-none focus:ring-2  focus:ring-blue-800/10 sm:text-sm',
        icon ? 'pl-14' : '',
      )}
    >
      {options.map((option) =>
        typeof option === 'string' ? (
          <option key={option} value="">
            {option}
          </option>
        ) : (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ),
      )}
    </select>
    {icon && (
      <div className="absolute left-px top-px bottom-px flex items-center justify-center rounded-l border-r border-slate-300 bg-slate-200 px-2 text-slate-500 shadow-sm transition group-focus-within:border-blue-500 group-focus-within:bg-blue-500/20 group-focus-within:text-blue-500">
        <FontAwesomeIcon icon={icon} className="w-6"></FontAwesomeIcon>
      </div>
    )}
  </div>
);
