import { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { Button, ButtonLink, Input, Label } from '../components/atoms';
import { CreateCustomerRequest } from '../types/api/customers';

export const NewCustomerPage = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firstnameSurname, setFirstnameSurname] = useState('');

  const history = useHistory();

  const mutation = useMutation((newCustomer: CreateCustomerRequest) =>
    api().post(Endpoints.Customers.Index, newCustomer),
  );

  function onSubmit() {
    mutation.mutate(
      { email, phone, firstnameSurname },
      {
        onError: (error) => {
          var message = (error as AxiosError)?.response?.data ?? 'Nepodařilo se vytvořit nového zákazníka!';
          toast(message, { type: 'error' });
        },
        onSuccess: () => {
          history.push('/zakaznici');
          toast(`Zákazník ${firstnameSurname} byl vytvořen`, { type: 'success' });
        },
      },
    );
  }

  return (
    <>
      <main className="p-6">
        <header className="mx-auto mb-4 flex w-full max-w-xl justify-start">
          <div>
            <h1 className="mb-2 text-3xl font-semibold tracking-wide">Vytvořit nového zákazníka</h1>
            <p className="text-sm text-gray-500">
              Na Email zákazníka bude zaslán email pro vytvoření hesla k jeho účtu
            </p>
          </div>
        </header>
        <form action="" className="mx-auto max-w-xl space-y-4">
          <div>
            <Label htmlFor="email">Email</Label>
            <Input
              icon="envelope"
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(value) => setEmail(value)}
            />
          </div>
          <div>
            <Label htmlFor="tel">Telefon</Label>
            <Input icon="phone" type="tel" name="tel" id="tel" value={phone} onChange={(value) => setPhone(value)} />
          </div>
          <div>
            <Label htmlFor="tel">Jméno</Label>
            <Input
              icon="id-card"
              type="text"
              name="name"
              id="name"
              value={firstnameSurname}
              onChange={(value) => setFirstnameSurname(value)}
            />
          </div>
          <div className="flex justify-end gap-4">
            <ButtonLink icon="times" variant="gray" to="/zakaznici">
              Zrušit
            </ButtonLink>
            <Button icon="check" variant="blue" onClick={() => onSubmit()} isLoading={mutation.isLoading}>
              Potvrdit
            </Button>
          </div>
        </form>
      </main>
    </>
  );
};
