import { InfoHotspot } from '../../types';
import { Guid } from '../../types/api';
import { Hotspot as MarzipanoHotspot } from '../../types/marzipano-types';

export type InfoHotspotAction =
  | OpenInfoHostpotModalAction
  | CloseInfoHostpotModalAction
  | ConfirmInfoHotspotModalAction
  | CancelInfoHotspotPlaceAction
  | CreateInfoHotspotAction
  | CreateInfoHotspotSuccessAction
  | DeleteInfoHotspotAction
  | DeleteInfoHotspotSuccessAction
  | UpdateInfoHotspotAction
  | UpdateInfoHotspotSuccessAction;

export type OpenInfoHostpotModalAction = {
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.MODAL.OPEN';
  payload: undefined | InfoHotspot;
};

export type CloseInfoHostpotModalAction = {
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.MODAL.CLOSE';
};

export type ConfirmInfoHotspotModalAction = {
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.MODAL.CONFIRM';
  payload: {
    id: Guid;
    title: string;
    description: string;
    thumbnailEdit: boolean;
    thumbnail: File | undefined;
    link: string;
  };
};

export type ConfirmInfoHotspotPlaceAction = {
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.PLACE.CONFIRM';
  payload: {
    yaw: number;
    pitch: number;
  };
};

export type CancelInfoHotspotPlaceAction = {
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.PLACE.CANCEL';
};

export type CreateInfoHotspotAction = {
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.CREATE';
  payload: {
    title: string;
    yaw: number;
    pitch: number;
    description: string;
    thumbnail: File | undefined;
    link: string | undefined;
  };
};

export type CreateInfoHotspotSuccessAction = {
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.CREATE.SUCCESS';
  payload: {
    id: Guid;
    title: string;
    yaw: number;
    pitch: number;
    description: string;
    thumbnailName: string | undefined;
    thumbnailUrl: string | undefined;
    link: string | undefined;
    hotspotRef: MarzipanoHotspot;
  };
};

export type DeleteInfoHotspotAction = {
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.DELETE';
  payload: {
    id: string;
  };
};

export type UpdateInfoHotspotAction = {
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.UPDATE';
  payload: {
    id: Guid;
    title: string;
    description: string;
    thumbnailEdit: boolean;
    thumbnail: File | undefined;
    link: string;
  };
};

export type UpdateInfoHotspotSuccessAction = {
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.UPDATE.SUCCESS';
  payload: {
    id: Guid;
    title: string;
    yaw: number;
    pitch: number;
    description: string;
    thumbnailUrl?: string;
    thumbnailName?: string;
    link: string;
  };
};

export type DeleteInfoHotspotSuccessAction = {
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.DELETE.SUCCESS';
  payload: {
    id: string;
  };
};

export const openInfoHotspotModal = (hotspot?: InfoHotspot): InfoHotspotAction => ({
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.MODAL.OPEN',
  payload: hotspot,
});

export const closeInfoHotspotModal = (): InfoHotspotAction => ({
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.MODAL.CLOSE',
});

export const confirmInfoHotspotModal = (
  id: Guid,
  title: string,
  description: string,
  thumbnailEdit: boolean,
  thumbnail: File | undefined,
  link: string,
): ConfirmInfoHotspotModalAction => ({
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.MODAL.CONFIRM',
  payload: {
    id,
    title,
    description,
    thumbnailEdit,
    thumbnail,
    link,
  },
});

export const cancelInfoHotspotPlace = (): InfoHotspotAction => ({
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.PLACE.CANCEL',
});

export const createInfoHotspot = (
  title: string,
  yaw: number,
  pitch: number,
  description: string,
  thumbnail: File | undefined,
  link: string | undefined,
): CreateInfoHotspotAction => ({
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.CREATE',
  payload: {
    title,
    yaw,
    pitch,
    description,
    thumbnail,
    link,
  },
});

export const createInfoHotspotSucess = (
  id: Guid,
  title: string,
  yaw: number,
  pitch: number,
  description: string,
  thumbnailName: string | undefined,
  thumbnailUrl: string | undefined,
  link: string | undefined,
  hotspotRef: MarzipanoHotspot,
): InfoHotspotAction => ({
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.CREATE.SUCCESS',
  payload: {
    id,
    title,
    yaw,
    pitch,
    description,
    thumbnailName,
    thumbnailUrl,
    link,
    hotspotRef,
  },
});

export const deleteInfoHotspot = (id: string): InfoHotspotAction => ({
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.DELETE',
  payload: {
    id: id,
  },
});

export const deleteInfoHotspotSuccess = (id: string): InfoHotspotAction => ({
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.DELETE.SUCCESS',
  payload: {
    id: id,
  },
});

export const updateInfoHotspot = (
  id: Guid,
  title: string,
  description: string,
  thumbnailEdit: boolean,
  thumbnail: File | undefined,
  link: string,
): InfoHotspotAction => ({
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.UPDATE',
  payload: {
    id,
    title,
    description,
    thumbnailEdit,
    thumbnail,
    link,
  },
});

export const updateInfoHotspotSuccess = (
  id: Guid,
  title: string,
  yaw: number,
  pitch: number,
  description: string,
  thumbnailName: string | undefined,
  thumbnailUrl: string | undefined,
  link: string,
): InfoHotspotAction => ({
  type: 'PANORAMA.EDIT.INFO_HOTSPOT.UPDATE.SUCCESS',
  payload: {
    id,
    title,
    yaw,
    pitch,
    description,
    thumbnailName,
    thumbnailUrl,
    link,
  },
});
