import { Transition } from '@headlessui/react';
import React, { createRef, Fragment, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSwipeable } from 'react-swipeable';

import { classNames } from '../../utils';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  images: {
    url: string;
    thumbnailUrl: string;
    alt: string;
  }[];
};

function useDebounce<T>(initialValue: T, time: number): [T, T, React.Dispatch<T>] {
  const [value, setValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedValue(value);
    }, time);

    return () => {
      clearTimeout(debounce);
    };
  }, [value, time]);

  return [debouncedValue, value, setValue];
}

export const Gallery = ({ title, images, isOpen, onClose }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [translate, setTranslate] = useState<number>();
  useHotkeys('esc', () => onClose());
  const outerCarouselRef = createRef<HTMLDivElement>();
  const innerCarouselRef = createRef<HTMLDivElement>();
  const contentCarouselRef = createRef<HTMLDivElement>();
  const [debouncedValue, value, setValue] = useDebounce<KeyboardEvent>(
    typeof window === 'undefined'
      ? (undefined as unknown as KeyboardEvent) // To fix SSR build
      : new KeyboardEvent('', {}),
    250,
  );
  const itemMap = images.map((item, index) => {
    return { item, index };
  });

  function keyDownListener(event: KeyboardEvent) {
    if (isOpen) setValue(event);
  }

  useEffect(() => {
    window.addEventListener('keydown', keyDownListener);

    return () => {
      window.removeEventListener('keydown', keyDownListener);
    };
  });

  useEffect(() => {
    debouncedValue.key === 'ArrowDown' && onPreviousClick();
    debouncedValue.key === 'ArrowLeft' && onPreviousClick();
    debouncedValue.key === 'ArrowUp' && onNextClick();
    debouncedValue.key === 'ArrowRight' && onNextClick();
  }, [debouncedValue]);

  useEffect(() => {
    if (
      innerCarouselRef.current &&
      innerCarouselRef.current.clientWidth > 0 &&
      outerCarouselRef.current &&
      outerCarouselRef.current.clientWidth > 0 &&
      activeIndex > -1
    ) {
      const translateToCenter = outerCarouselRef.current.clientWidth / 2;
      const imageWidth = innerCarouselRef.current.children[0]?.clientWidth;

      const translateByActiveImage = imageWidth * activeIndex + 20 * activeIndex - 1;

      setTranslate(translateToCenter - imageWidth / 2 - translateByActiveImage);
    }
  }, [activeIndex, innerCarouselRef, outerCarouselRef]);

  function onPreviousClick() {
    setActiveIndex(activeIndex === 0 ? images.length - 1 : activeIndex - 1);
  }

  function onNextClick() {
    setActiveIndex(activeIndex >= images.length - 1 ? 0 : activeIndex + 1);
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (_) => onNextClick(),
    onSwipedRight: (_) => onPreviousClick(),
    onSwipedDown: (_) => onClose(),
    onSwipedUp: (_) => onClose(),
    delta: {
      down: 200,
      up: 200,
      left: 50,
      right: 50,
    },
  });

  return ReactDOM.createPortal(
    <Transition show={isOpen} as={Fragment}>
      <dialog className="fixed inset-0 z-[2000] m-0 h-full w-full overflow-y-auto bg-transparent p-0" open>
        <div className="h-full overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-90" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative z-10 flex h-full flex-col">
              {/*  Top action bar */}
              <div className="flex justify-between gap-2 px-3 py-4 text-sm text-gray-300 lg:px-6 lg:text-base">
                <div className="">
                  {activeIndex + 1} / {images.length}
                </div>
                <h3 className="">{title}</h3>
                <button
                  className="transition duration-300 hover:text-white focus:text-white focus:outline-none"
                  onClick={() => onClose()}
                >
                  <svg
                    className="h-5 w-5 lg:h-6 lg:w-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>

              {/* Main content */}
              <div
                {...swipeHandlers}
                className="grow overflow-hidden py-8"
                onClick={() => onClose()}
                ref={contentCarouselRef}
              >
                <div
                  className="h-full w-full grow  whitespace-nowrap py-8 transition duration-300"
                  style={{
                    transform: `translateX(${-activeIndex * 100}%)`,
                  }}
                  onClick={() => onClose()}
                >
                  {itemMap.map((item) => (
                    <div key={item.item.alt} className="inline-flex h-full w-full items-center justify-center">
                      <img
                        alt={item.item.alt}
                        key={item.item.alt}
                        src={item.item.url}
                        className="mx-auto h-full object-contain opacity-100"
                        onClick={(e) => e.stopPropagation()}
                        loading="lazy"
                      />
                    </div>
                  ))}
                </div>
              </div>

              {/* Bottom navigation preview */}
              <div className="overflow-hidden px-3 py-2 lg:px-6 lg:py-4" ref={outerCarouselRef}>
                <div
                  className={classNames(
                    'flex w-full justify-start space-x-4 whitespace-nowrap transition duration-300',
                  )}
                  ref={innerCarouselRef}
                  style={{
                    transform: translate ? `translateX(${translate}px)` : `translateX(calc(50% - 48px))`,
                  }}
                >
                  {images.map((image, index) => (
                    <button
                      key={image.alt}
                      className={classNames(
                        'border-2 transition duration-300 hover:opacity-100 focus:opacity-100 focus:outline-none',
                        activeIndex === index ? 'border-gray-200 opacity-90' : 'border-transparent opacity-75',
                      )}
                      onClick={() => setActiveIndex(index)}
                    >
                      <div>
                        <img
                          alt={image.alt}
                          key={image.alt}
                          src={image.thumbnailUrl}
                          className="aspect-[4/3] h-14 object-cover opacity-100 md:h-16 lg:h-20 xl:h-24"
                        />
                      </div>
                    </button>
                  ))}
                </div>
              </div>

              {/* Arrow buttons */}
              <button
                className="absolute left-4 top-1/2 z-20 -translate-y-1/2 transform bg-black bg-opacity-50 p-1 text-gray-200  hover:text-white focus:text-white focus:outline-none"
                onClick={() => onPreviousClick()}
              >
                <svg
                  className="h-6 w-6 lg:h-8 lg:w-8"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.535 3.515L7.05005 12L15.535 20.485L16.95 19.071L9.87805 12L16.95 4.929L15.535 3.515Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              <button
                className="absolute right-4 top-1/2 z-20 -translate-y-1/2 transform bg-black bg-opacity-50 p-1 text-gray-200 text-gray-200 hover:text-white focus:text-white focus:outline-none"
                onClick={() => onNextClick()}
              >
                <svg
                  className="h-6 w-6 lg:h-8 lg:w-8"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.46495 20.485L16.95 12L8.46495 3.515L7.04995 4.929L14.122 12L7.04995 19.071L8.46495 20.485Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </Transition.Child>
        </div>
      </dialog>
    </Transition>,
    document.body,
  );
};
