export const Endpoints = {
  Account: {
    Index: '/account',
    Login: '/account/login',
    Password: '/account/password',
    Confirm: '/account/confirm',
  },
  Customers: {
    Index: '/customers',
  },
  InfoHotspots: {
    Index: '/info-hotspots',
    Create: '/info-hotspots',
    Delete: (id: string) => `/info-hotspots/${id}`,
    Update: (id: string) => `/info-hotspots/${id}`,
  },
  MainHotspots: {
    Index: '/main-hotspots',
    Create: '/main-hotspots',
    Delete: (id: string) => `/main-hotspots/${id}`,
    Update: (id: string) => `/main-hotspots/${id}`,
  },
  Panoramas: {
    Index: '/panoramas',
    Detail: (id: string) => `/panoramas/${id}`,
    My: '/panoramas/my',
    New: '/panoramas/new',
    Create: '/panoramas',
    Face: (id: string, level: number, faceName: string, y: number, x: number) =>
      `/panoramas/${id}/${level}/${faceName}/${y}/${x}`,
    Delete: (id: string) => `/panoramas/${id}`,
    Update: (id: string) => `/panoramas/${id}`,
    Output: (id: string) => `/panoramas/${id}/output`,
  },
};
