import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { QueryParamProvider } from 'use-query-params';
import App from './App';
import store from './redux';
import './styles/index.css';

library.add(fas as any, fab as any);

const theme = {
  'slate-50': 'rgb(248 250 252)',
  'slate-100': 'rgb(241 245 249)',
  'slate-200': 'rgb(226 232 240)',
  'slate-300': 'rgb(203 213 225)',
  'slate-400': 'rgb(148 163 184)',
  'slate-500': 'rgb(100 116 139)',
  'slate-600': 'rgb(71 85 105)',
  'slate-700': 'rgb(51 65 85)',
  'slate-800': 'rgb(30 41 59)',
  'slate-900': 'rgb(15 23 42)',
  'slate-950': 'rgb(2 6 23)',
};

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);
