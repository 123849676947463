import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { api, toastError } from '../../api';
import { Endpoints } from '../../api/endpoints';
import { Alert, Input, Label } from '../../components/atoms';
import { AccountInfoResponse, UpdateAccountRequest, UpdatePasswordRequest } from '../../types/api/account';
import { LoadingSkeleton, PasswordChangeForm, PersonalInfoForm } from './components';

export const AccountPage = () => {
  const query = useQuery(['account'], () => api().get<AccountInfoResponse>(Endpoints.Account.Index), {
    refetchOnWindowFocus: false,
  });

  const editMutation = useMutation((request: UpdateAccountRequest) => api().put(Endpoints.Account.Index, request), {
    onSuccess: () => {
      toast('Změny vašeho účtu byly uloženy.', { type: 'success' });
      query.refetch();
    },
    onError: (error: AxiosError) => {
      toast(error.response?.data, { type: 'error' });
    },
  });

  const editPasswordMutation = useMutation(
    (request: UpdatePasswordRequest) => api().put(Endpoints.Account.Password, request),
    {
      onSuccess: () => {
        toast('Vaše nové heslo bylo uloženo.', { type: 'success' });
        query.refetch();
      },
      onError: (error: AxiosError) => {
        toastError(error, 'Nepodařilo se uložit nové heslo');
      },
    },
  );

  return (
    <>
      <main className="p-6">
        <h1 className="mx-auto mb-6 max-w-xl text-3xl font-semibold tracking-wide">Váš účet</h1>
        {query.isLoading ? (
          <LoadingSkeleton />
        ) : query.isError ? (
          <Alert variant="error">{(query.error as AxiosError)?.response?.data ?? 'Nepodařilo se načíst data!'}</Alert>
        ) : (
          <>
            <section className="mx-auto mb-4 w-full max-w-xl">
              <div>
                <Label htmlFor="email">Email</Label>
                <Input
                  icon="envelope"
                  type="email"
                  name="email"
                  id="email"
                  value={query.data?.data.email ?? ''}
                  disabled
                  onChange={() => {}}
                />
                <small className="text-xs text-gray-500">Email nelze změnit.</small>
              </div>
            </section>
            <hr className="mx-auto my-8 max-w-2xl border-slate-300" />
            <PersonalInfoForm
              email={query.data?.data.email ?? ''}
              initialValues={query.data?.data!}
              isLoading={editMutation.isLoading}
              onSubmit={(data) => editMutation.mutate({ ...data })}
            />
            <hr className="mx-auto my-8 max-w-2xl border-slate-300" />
            <PasswordChangeForm
              email={query.data?.data.email ?? ''}
              isLoading={editPasswordMutation.isLoading}
              onSubmit={(data) => editPasswordMutation.mutate({ ...data })}
            />
          </>
        )}
      </main>
    </>
  );
};
