import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { classNames } from '../../utils';

type Props = {
  name: string;
  id: string;
  icon?: IconProp;
  disabled?: boolean;
  onChange: (file: File) => void;
  onRemove: () => void;
  fileName: string | undefined;
};

export const FileInput = ({ name, id, icon, disabled, onChange, onRemove, fileName }: Props) => {
  const [file, setFile] = useState<File>();
  const [isDeleted, setIsDeleted] = useState(false);
  const hasFile = isDeleted === false && (file || fileName);
  const fileNameInternal = isDeleted ? 'Soubor odstraněn' : file?.name ?? fileName ?? 'Nevybrán  žádný soubor';

  const className = classNames(
    'block w-full border rounded-l overflow-hidden border-slate-300 px-4 py-2 transition hover:border-blue-500 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-800/10 sm:text-sm appearance-none',
    'disabled:bg-slate-100 disabled:text-slate-600 disabled:hover:border-slate-400 disabled:border-slate-300 disabled:cursor-not-allowed hover:z-10 focus:z-10',
    icon ? 'pl-14' : '',
  );

  const iconClassName = classNames(
    'absolute left-px top-px bottom-px  flex items-center justify-center border-r px-2 text-slate-500 shadow-sm transition group-focus-within:border-blue-500 group-focus-within:bg-blue-500/20 group-focus-within:text-blue-500 rounded-l',
    disabled ? 'bg-slate-200 border-slate-300' : 'bg-slate-200 border-slate-300',
  );

  return (
    <div className="group relative mt-1 flex items-stretch shadow-sm">
      {icon && (
        <div className={iconClassName}>
          <FontAwesomeIcon icon={icon} className="w-6"></FontAwesomeIcon>
        </div>
      )}
      <input
        type="file"
        name={name}
        accept="image/png, image/jpeg"
        id={id}
        disabled={disabled}
        className={className}
        onChange={(e) => {
          if (e.target?.files?.[0]) {
            setFile(e.target.files[0]);
            setIsDeleted(false);
            onChange(e.target.files[0]);
          }
        }}
      />
      <span
        className={classNames(
          'pointer-events-none absolute left-[42px] top-px bottom-px right-[43px] z-[80] flex items-center bg-white px-4 py-2 text-sm ',
          hasFile ? 'text-slate-900' : 'font-light text-slate-400',
        )}
      >
        {fileNameInternal}
      </span>
      <button
        type="button"
        className="-ml-px block rounded-r border border-slate-300 bg-slate-100 px-2 text-sm text-slate-600 transition hover:border-red-500 hover:bg-red-100 hover:text-red-500"
        title="Odstranit soubor"
        onClick={() => {
          setFile(undefined);
          setIsDeleted(true);
          onRemove();
        }}
      >
        <FontAwesomeIcon icon="trash" className="w-6"></FontAwesomeIcon>
      </button>
    </div>
  );
};
