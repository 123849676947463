import { useForm } from 'react-hook-form';
import { Button } from '../../../components/atoms';
import { InputField } from '../../../components/molecules';

type FormState = {
  phone: string;
  firstnameSurname: string;
};

type Props = {
  email: string;
  isLoading: boolean;
  initialValues: FormState;
  onSubmit: (form: FormState) => void;
};

export const PersonalInfoForm = ({ email, isLoading, initialValues, onSubmit }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>({ mode: 'onBlur', shouldFocusError: false, defaultValues: initialValues });

  return (
    <section className="mx-auto max-w-xl">
      <header className="space-y-2">
        <h2 className="text-2xl font-semibold tracking-wide">Osobní údaje</h2>
        <p className="text-sm text-gray-500">Zde můžete změnit vaše osobní údaje.</p>
      </header>
      <form action="" className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="email" id="passwordEmail" value={email} />
        <InputField
          type="tel"
          label="Telefon"
          icon="phone"
          register={register('phone', {
            required: 'Telefon musí být vyplněn',
          })}
          error={errors.phone}
        />
        <InputField
          type="text"
          label="Jméno"
          icon="id-card"
          register={register('firstnameSurname', {
            required: 'Jméno musí být vyplněno',
          })}
          error={errors.firstnameSurname}
        />
        <div className="flex justify-end gap-4">
          <Button icon="check" variant="blue" type="submit" isLoading={isLoading}>
            Uložit
          </Button>
        </div>
      </form>
    </section>
  );
};
