import { useForm } from 'react-hook-form';
import { Button } from '../../../components/atoms';
import { InputField } from '../../../components/molecules';

type FormState = {
  password: string;
  newPassword: string;
  newPasswordRepeat: string;
};

type Props = {
  email: string;
  isLoading: boolean;
  onSubmit: (form: FormState) => void;
};

export const PasswordChangeForm = ({ email, isLoading, onSubmit }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>({ mode: 'onBlur', shouldFocusError: false });

  return (
    <section className="mx-auto max-w-xl">
      <header className="space-y-2">
        <h2 className="text-2xl font-semibold tracking-wide">Změna hesla</h2>
        <p className="text-sm text-gray-500">Zde můžete změnit vaše heslo.</p>
      </header>
      <form action="" className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="email" id="passwordEmail" value={email} />

        <InputField
          type="password"
          label="Původní heslo"
          icon="lock"
          register={register('password', {
            required: 'Původní heslo musí být vyplněno',
          })}
          error={errors.password}
        />
        <InputField
          type="password"
          label="Nové heslo"
          icon="lock"
          register={register('newPassword', {
            required: 'Nové heslo musí být vyplněno',
          })}
          error={errors.newPassword}
        />
        <InputField
          type="password"
          label="Zopakujte nové heslo"
          icon="lock"
          register={register('newPasswordRepeat', {
            required: 'Zopakování nového hesla musí být vyplněno',
            validate: {
              value: (value, formState) => value !== formState.newPassword,
              message: () => 'Zadaná hesla se musejí shodovat',
            },
          })}
          error={errors.newPasswordRepeat}
        />
        <div className="flex justify-end gap-4">
          <Button icon="check" variant="blue" type="submit" isLoading={isLoading}>
            Uložit
          </Button>
        </div>
      </form>
    </section>
  );
};
