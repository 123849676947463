import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { classNames } from '../../utils';
import { Spinner } from './Spinner';

type Props = {
  onClick?: () => void;
  children?: ReactNode;
  variant: 'blue' | 'gray' | 'red' | 'transparent';
  size?: 'regular' | 'small' | 'smallSquare' | 'tiny';
  type?: 'button' | 'submit';
  isLoading?: boolean;
  isDisabled?: boolean;
  icon?: IconName;
  title?: string;
  isFullWidth?: boolean;
};
export const Button = ({
  onClick,
  children,
  variant,
  isLoading,
  isDisabled,
  type = 'button',
  icon,
  size = 'regular',
  title,
  isFullWidth,
}: Props) => (
  <>
    <button
      className={classNames(
        'inline-flex items-center justify-center rounded-md border align-middle transition duration-300 focus:outline-none disabled:cursor-not-allowed',
        variant === 'blue' &&
          'border-transparent bg-blue-500 text-gray-50 shadow-sm shadow-blue-500/20 hover:bg-blue-600 hover:text-white disabled:hover:bg-blue-500',
        variant === 'red' &&
          'border-transparent bg-red-600 text-gray-50 shadow-sm shadow-red-500/20 hover:bg-red-600  hover:text-white',
        variant === 'gray' &&
          'border-gray-300 bg-white text-gray-700 shadow-sm hover:border-gray-300 hover:bg-gray-50  hover:text-gray-800',
        variant === 'transparent' &&
          'border-gray-300/0 text-gray-700 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800 ',
        size === 'regular' && 'gap-3 px-4 py-2 text-sm font-medium tracking-wide',
        size === 'small' && 'gap-3 px-4 py-2 text-sm font-medium tracking-wide',
        size === 'tiny' && 'gap-3 px-3.5 py-1.5 text-[13px] font-medium tracking-wide',
        size === 'smallSquare' && 'gap-3 px-2 py-2 text-sm font-medium',
        isFullWidth && 'w-full',
      )}
      type={type}
      disabled={isDisabled || isLoading}
      onClick={(_) => onClick?.()}
      title={title}
    >
      {isLoading && <Spinner className="h-5 w-5"></Spinner>}
      {!isLoading && icon && (
        <FontAwesomeIcon
          icon={icon}
          className={classNames(size === 'small' && 'h-3.5 w-3.5', size === 'regular' && 'h-4 w-4')}
        />
      )}
      {children}
    </button>
  </>
);
