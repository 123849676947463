import { Skeleton } from '../../../components/atoms/Skeleton';
import { CustomersTableHead } from './CustomersTableHead';

export const LoadingSkeleton = () => {
  return (
    <div className="overflow-hidden rounded-md border border-slate-200 bg-white shadow">
      <table className="w-full">
        <CustomersTableHead />
        <tbody>
          <tr className="border-b border-slate-100">
            <td className="px-4 py-3 text-sm font-semibold text-slate-800">
              <div className="flex items-center gap-6">
                <Skeleton width={48} height={48} />
                <Skeleton height={20} width={160} />
              </div>
            </td>
            <td className="px-4 py-3 text-sm text-slate-800">
              <Skeleton height={20} width={200} />
            </td>
            <td className="px-4 py-3 text-sm text-slate-800">
              <Skeleton height={20} width={300} />
            </td>
            <td className="px-4 py-3 text-sm text-slate-800">
              <Skeleton height={20} width={20} />
            </td>
            <td className="px-4 py-3 text-sm text-slate-800">
              <div className="flex justify-end gap-4">
                <Skeleton height={38} width={157} />
                <Skeleton height={38} width={108} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
