import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure, Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { classNames } from '../../utils';

type Props = {
  name: string;
  description: string;
  link: string;
  thumbnailUrl: string | undefined;
  style: {
    background: string;
    foreground: string;
  };
};

export const InfoHotspotWithLink = ({ name, description, thumbnailUrl, link, style }: Props) => {
  const [thumbnailStamp, setThumbnailStamp] = useState(0);
  useEffect(() => setThumbnailStamp(new Date().getTime()), [thumbnailUrl]);

  return (
    <Disclosure>
      {({ close }) => (
        <div
          className="group relative z-[60]  -ml-[16px] -mt-[16px]"
          onMouseLeave={() => {
            close();
          }}
        >
          <Disclosure.Button className="relative z-10 flex focus:outline-none">
            <div
              className="flex h-8 w-8 items-center justify-center rounded-lg outline-2 outline-offset-4 transition-all duration-500 "
              style={{
                backgroundColor: style.background,
                color: style.foreground,
              }}
            >
              <FontAwesomeIcon icon="info" />
            </div>
            <h3
              className="-ml-2 flex h-8 w-0 items-center justify-start overflow-hidden rounded-r-lg font-medium transition-all  duration-500 group-hover:w-[228px]"
              style={{
                backgroundColor: style.background,
                color: style.foreground,
              }}
            >
              <span className="ml-4">{name}</span>
            </h3>
          </Disclosure.Button>
          <Transition
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-300 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="-mt-2 w-[250px] overflow-hidden rounded-b-lg bg-slate-900/90 text-gray-500">
              {thumbnailUrl && (
                <img
                  width={250}
                  height={166}
                  className="w-full transition duration-300"
                  src={`${thumbnailUrl}?${thumbnailStamp}`}
                  alt=""
                />
              )}
              <div className="overflow-hidden  whitespace-pre-wrap  px-4 pb-4 pt-5 text-sm leading-relaxed text-slate-200">
                <div>
                  <div className="rich-output" dangerouslySetInnerHTML={{ __html: description }}></div>
                </div>
                <CustomLink
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer external"
                  className={classNames(
                    'relative flex items-center justify-center gap-4 border-2 transition hover:shadow-lg focus:outline-none',
                    'mt-4 rounded-md px-4 py-2 text-xs font-medium tracking-wide',
                  )}
                  background={style.background}
                  foreground={style.foreground}
                >
                  Odkaz
                </CustomLink>
              </div>
            </Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
};

const CustomLink = styled.a<{ background: string; foreground: string }>`
  background: ${(props) => props.background};
  color: ${(props) => props.foreground};
  border-color: rgb(15 23 42);
  &:hover {
    border-color: ${(props) => [props.foreground]} !important;
  }
`;
