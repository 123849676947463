import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, ButtonLink, Input, Label, Select } from '../../components/atoms';
import { fetch, fileUploaded, savePanorama } from '../../redux/actions/panorama.new';
import { StoreState } from '../../redux/reducers';

export const NewPanoramaPage = () => {
  const dispatch = useDispatch();

  const [isEnabled, setIsEnabled] = useState(true);
  const [title, setTitle] = useState('');
  const [customer, setCustomer] = useState('');
  const defaultDate = new Date();
  defaultDate.setFullYear(new Date().getFullYear() + 1);
  const [expireDate, setExpireDate] = useState<Date>(defaultDate);
  const [previewUrl, setPreviewUrl] = useState('');

  const state = useSelector((state: StoreState) => state.panoramaNew);

  useEffect(() => {
    dispatch(fetch());
  }, [dispatch]);

  useEffect(() => {
    if (state.state === 'UploadingToServer') {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [state.state]);

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (customer === '') {
      setCustomer(Object.keys(state.availableCustomers)[0]);
      dispatch(savePanorama(title, Object.keys(state.availableCustomers)[0], isEnabled, expireDate));
    } else {
      dispatch(savePanorama(title, customer, isEnabled, expireDate));
    }
  };

  async function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    const blob = new Blob([file], { type: file.type });

    // Generate a Blob URL
    const blobUrl = URL.createObjectURL(blob);
    setPreviewUrl(blobUrl);

    dispatch(fileUploaded(file));
  }

  return (
    <>
      {state.mutation.state === 'Success' && <Redirect to="/" />}
      <main className="p-6">
        <header className="mx-auto mb-6 flex w-full max-w-lg justify-start">
          <div>
            <h1 className="mb-2 text-3xl font-semibold tracking-wide">Vytvořit nové panorama</h1>
            <p className="text-sm text-gray-500">Lorem ipsum</p>
          </div>
        </header>
        <form onSubmit={onSubmit} className="mx-auto max-w-lg space-y-6">
          <button type="submit" disabled className="d-none absolute"></button>
          <div>
            <Label htmlFor="title">Název</Label>
            <Input type="text" name="title" id="title" value={title} onChange={(value) => setTitle(value)} />
          </div>
          <div>
            <Label htmlFor="customer">Zákazník</Label>
            <Select
              name="customer"
              id="customer"
              value={customer}
              onChange={(value) => setCustomer(value)}
              options={Object.keys(state.availableCustomers).map((key) => ({
                label: state.availableCustomers[key],
                value: key,
              }))}
            />
          </div>
          <div>
            <Label htmlFor="expireDate">Datum expirace</Label>
            <Input
              name="expireDate"
              id="expireDate"
              type="date"
              value={new Date(expireDate).toISOString().substring(0, 10)}
              onChange={(value) => setExpireDate(value ? new Date(value) : new Date())}
            />
          </div>
          <div className="group relative flex items-center rounded-md border border-slate-300 bg-white px-4 py-2.5 transition duration-300 hover:border-blue-500 hover:bg-white">
            <label htmlFor="enabled" className="min-w-0 flex-1 text-sm">
              <span className="block font-medium text-slate-700 transition duration-300 group-hover:text-slate-900">
                Zapnuto
              </span>
              <span className="block text-xs font-light text-slate-500 transition duration-300 group-hover:text-slate-600">
                Zapnutá panoramata jsou veřejně dostupná
              </span>
            </label>
            <div className="ml-3 flex h-5 items-center">
              <input
                id="enabled"
                name="enabled"
                type="checkbox"
                checked={isEnabled}
                onChange={() => setIsEnabled(!isEnabled)}
                className="h-4 w-4 rounded border-slate-300 accent-blue-600"
              />
            </div>
          </div>
          <div>
            <Label htmlFor="panoramaPhoto">Vstupní panoramatická fotka</Label>
            {state.state === 'Initial' && (
              <div className="relative mt-2 rounded-md border-2 border-dashed border-slate-300 bg-white px-6 pb-6 pt-5 transition duration-300 hover:border-blue-500">
                <input
                  type="file"
                  onChange={(e) => onFileChange(e)}
                  className="absolute inset-0 h-full w-full cursor-pointer appearance-none opacity-0"
                />
                <svg
                  className="mx-auto mb-2 h-12 w-12 text-slate-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <div className="text-center text-sm text-slate-700">Nahrajte panoramatickou fotku</div>
              </div>
            )}

            {state.state === 'FileLoading' && (
              <div className="relative mt-2 rounded-md border-2 border-dashed border-slate-300 bg-white px-6 pb-6 pt-5 transition duration-300 hover:border-blue-500">
                <svg
                  aria-hidden="true"
                  className="mx-auto mb-4 h-9 w-9 animate-spin fill-slate-500 text-slate-200"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <div className="text-center text-sm text-slate-700">Nahrávám fotku ({state.progress}%)</div>
                <div className="mx-auto mt-3 h-[12px] w-64 overflow-hidden rounded-full border border-blue-500 bg-white  shadow-lg shadow-blue-500/20">
                  <div
                    className="-mx-px h-[10px] rounded-full bg-blue-500"
                    style={{ width: `calc(${state.progress}% + 2px)` }}
                  ></div>
                </div>
              </div>
            )}

            {(state.state === 'FileLoaded' || state.state === 'UploadingToServer') && (
              <img
                className="overflow-hidden rounded-md border-2 border-slate-300 transition duration-300 hover:border-blue-500"
                src={previewUrl}
                alt=""
              />
            )}
          </div>
          {state.state === 'UploadingToServer' && (
            <div className="relative mt-2 rounded-md border-2 border-dashed border-slate-300 bg-white px-6 pb-6 pt-5 transition duration-300 hover:border-blue-500">
              <svg
                aria-hidden="true"
                className="mx-auto mb-4 h-9 w-9 animate-spin fill-slate-500 text-slate-200"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <div className="text-center text-sm text-slate-700">
                Odesílám data na server ({state.uploadProgress}%)
              </div>
              <div className="mx-auto mt-3 h-[12px] w-64 overflow-hidden rounded-full border border-blue-500 bg-white  shadow-lg shadow-blue-500/20">
                <div
                  className="-mx-px h-[10px] rounded-full bg-blue-500"
                  style={{ width: `calc(${state.uploadProgress}% + 2px)` }}
                ></div>
              </div>
            </div>
          )}
          <div className="flex justify-end gap-4">
            <ButtonLink icon="times" variant="gray" to="/">
              Zrušit
            </ButtonLink>
            <Button icon="check" variant="blue" type="submit" isDisabled={state.state !== 'FileLoaded'}>
              Potvrdit
            </Button>
          </div>
        </form>
      </main>
    </>
  );
};
