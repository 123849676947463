import { CKEditor } from '@ckeditor/ckeditor5-react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { InputHTMLAttributes } from 'react';
import { FieldError, UseFormSetValue } from 'react-hook-form';
import { classNames } from '../../utils';
import { InputError, Label } from '../atoms';

type Props = {
  type: 'email' | 'text' | 'search' | 'tel' | 'password' | 'file' | 'textarea' | 'date' | 'color' | 'rich-editor';
  register: React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  error: FieldError | undefined;
  label?: string;
  size?: 'regular' | 'tiny';
  icon?: IconProp;
  disabled?: boolean;
  setValue?: UseFormSetValue<any>;
};

export const InputField = (props: Props) => {
  const { type, register, error, label, size = 'regular', icon } = props;
  const isValid = !error?.message;

  const inputWrapperClassName = classNames(
    'group flex w-full overflow-hidden rounded-md transition',
    'border ',
    'focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-800/10',
    'disabled:bg-slate-100 disabled:text-slate-600 disabled:hover:border-slate-400 disabled:border-slate-300 disabled:cursor-not-allowed',
    isValid
      ? 'border-slate-300 hover:border-blue-500 focus-within:border-blue-500 '
      : 'border-red-500 hover:border-red-500 focus-within:border-red-500',
  );

  const inputClassName = classNames(
    'w-full focus:outline-none',
    size === 'regular' && 'px-4 py-2 text-sm font-medium tracking-wide',
    size === 'tiny' && 'px-3.5 py-1.5 text-[13px] font-medium tracking-wide',
  );

  const iconClassName = classNames(
    'flex items-center justify-center border-r  px-2 transition',
    'group-focus-within:text-blue-500 group-focus-within:bg-blue-100 group-focus-within:border-blue-500',
    isValid
      ? 'border-slate-300 bg-slate-200 text-slate-500 hover:border-blue-500 focus-within:border-blue-500 '
      : 'border-red-500 bg-red-200 text-red-500 hover:border-red-500 focus-within:border-red-500',
  );

  return (
    <div className="space-y-1">
      {label && <Label htmlFor={register.name ?? ''}>{label}</Label>}

      {type !== 'rich-editor' ? (
        <div className={inputWrapperClassName}>
          {icon && (
            <div className={iconClassName}>
              <FontAwesomeIcon className="w-6" icon={icon} />
            </div>
          )}
          <input className={inputClassName} id={register.name} type={type} {...register} />
        </div>
      ) : (
        <div className={`ck-editor-wrapper ${error ? 'is-invalid' : ''}`}>
          <CKEditor
            editor={Editor}
            data={register.value?.toString()}
            onChange={(_, editor) => props.setValue && props.setValue(register.name ?? '', editor.getData())}
            config={{
              link: {
                addTargetToExternalLinks: true,
              },
            }}
          />
        </div>
      )}

      {error && <InputError>{error.message}</InputError>}
    </div>
  );
};
