import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../api';
import { Endpoints } from '../api/endpoints';
import { Button, ColorInput, Input, Label } from '../components/atoms';
import { Modal } from '../components/molecules';
import { CustomerDetailResponse, EditCustomerRequest, EditCustomizationRequest } from '../types/api/customers';
import { classNames } from '../utils';

type UrlParams = {
  id: string;
};

export const EditCustomerPage = () => {
  const id = useParams<UrlParams>().id;
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firstnameSurname, setFirstnameSurname] = useState('');

  const [icon, setIcon] = useState<File>();
  const [iconEdit, setIconEdit] = useState<boolean>(false);
  const [iconPreviewUrl, setIconPreviewUrl] = useState('');

  const [mainHotspotBackground, setMainHotspotBackground] = useState('#000000');
  const [mainHotspotForeground, setMainHotspotForeground] = useState('#ffffff');
  const [infoHotspotBackground, setInfoHotspotBackground] = useState('#000000');
  const [infoHotspotForeground, setInfoHotspotForeground] = useState('#ffffff');
  const [extendedInfoHotspotBackground, setExtendedInfoHotspotBackground] = useState('#000000');
  const [extendedInfoHotspotForeground, setExtendedInfoHotspotForeground] = useState('#ffffff');

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const query = useQuery(
    ['customers', id],
    () => api().get<CustomerDetailResponse>(Endpoints.Customers.Index + `/${id}`),
    {
      onSuccess: (response) => {
        const { email, phone, firstnameSurname, customization } = response.data;
        setEmail(email);
        setPhone(phone);
        setFirstnameSurname(firstnameSurname);
        setMainHotspotBackground(customization.mainHotspotBackground);
        setMainHotspotForeground(customization.mainHotspotForeground);
        setInfoHotspotBackground(customization.infoHotspotBackground);
        setInfoHotspotForeground(customization.infoHotspotForeground);
        setExtendedInfoHotspotBackground(customization.extendedInfoHotspotBackground);
        setExtendedInfoHotspotForeground(customization.extendedInfoHotspotForeground);
        setIconPreviewUrl(customization.iconUrl + '?' + new Date().getTime());
      },
      refetchOnWindowFocus: false,
    },
  );

  const editMutation = useMutation(
    (customer: EditCustomerRequest) => api().put(Endpoints.Customers.Index + `/${id}`, customer),
    {
      onSuccess: () => {
        toast('Informace o zákazníkovi byly uloženy.', { type: 'success' });
        query.refetch();
      },
      onError: (error: AxiosError) => {
        toast(error.response?.data, { type: 'error' });
      },
    },
  );

  const editCustomizationMutation = useMutation(
    (request: EditCustomizationRequest) => {
      const formData = new FormData();
      formData.append('mainHotspotBackground', request.mainHotspotBackground);
      formData.append('mainHotspotForeground', request.mainHotspotForeground);
      formData.append('infoHotspotBackground', request.infoHotspotBackground);
      formData.append('infoHotspotForeground', request.infoHotspotForeground);
      formData.append('extendedInfoHotspotBackground', request.extendedInfoHotspotBackground);
      formData.append('extendedInfoHotspotForeground', request.extendedInfoHotspotForeground);
      formData.append('iconEdit', request.iconEdit ? 'true' : 'false');
      request.icon && formData.append('icon', request.icon);

      return api().put(Endpoints.Customers.Index + `/${id}/panorama-customization`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    {
      onSuccess: () => {
        toast('Stylování panoramat bylo uloženo.', { type: 'success' });
        query.refetch();
      },
      onError: (error: AxiosError) => {
        toast(error.response?.data, { type: 'error' });
      },
    },
  );

  const deleteMutation = useMutation(() => api().delete(Endpoints.Customers.Index + `/${id}`), {
    onSuccess: () => {
      toast(`Zákazník ${firstnameSurname} byl odstraněne.`, { type: 'success' });
      history.push('/zakaznici');
    },
    onError: (error: AxiosError) => {
      toast(error.response?.data, { type: 'error' });
    },
  });

  const onIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!file) return;

    const blob = new Blob([file], { type: file.type });

    // Generate a Blob URL
    const blobUrl = URL.createObjectURL(blob);
    setIconPreviewUrl(blobUrl);
    setIcon(file);
    setIconEdit(true);
  };

  const onIconLoadError = (e: React.ChangeEvent<HTMLImageElement>) => {
    setIconPreviewUrl('');
  };

  const onIconDelete = () => {
    setIcon(undefined);
    setIconEdit(true);
    setIconPreviewUrl('');
  };

  return (
    <>
      <main className="p-6">
        <header className="mx-auto flex w-full max-w-xl justify-start">
          <h1 className="text-3xl font-extrabold tracking-wide">Nastavení zákazníka</h1>
        </header>

        {/* Profile */}
        <section className="mx-auto max-w-xl py-8">
          <h2 className="text-xl font-medium text-slate-900">Profil</h2>
          <p className="mt-1 text-sm text-slate-500">
            Obecné informace o zákazníkovi. Zde můžete upravit telefonní číslo a jméno zákazníka.
          </p>
          <form
            action=""
            className="mt-6 space-y-4"
            onSubmit={(e) => {
              e.preventDefault();
              editMutation.mutate({ phone, firstnameSurname });
            }}
          >
            <input type="submit" disabled className="hidden" value="submit" />
            <div>
              <Label htmlFor="email">Email</Label>
              <Input
                icon="envelope"
                type="email"
                name="email"
                id="email"
                value={email}
                disabled
                onChange={(value) => setEmail(value)}
              />
              <small className="text-xs text-gray-600">Email zákazníka nelze změnit.</small>
            </div>
            <div>
              <Label htmlFor="tel">Telefon</Label>
              <Input icon="phone" type="tel" name="tel" id="tel" value={phone} onChange={(value) => setPhone(value)} />
            </div>
            <div>
              <Label htmlFor="name">Jméno</Label>
              <Input
                icon="id-card"
                type="text"
                name="name"
                id="name"
                value={firstnameSurname}
                onChange={(value) => setFirstnameSurname(value)}
              />
            </div>
            <div className="flex justify-end gap-4">
              <Button
                icon="trash"
                variant="red"
                onClick={() => setIsDeleteModalOpen(true)}
                isLoading={editMutation.isLoading}
              >
                Odstranit zákazníka
              </Button>
              <Button icon="check" variant="blue" type="submit" isLoading={editMutation.isLoading}>
                Uložit
              </Button>
            </div>
          </form>
        </section>

        {/* Panorama customization */}
        <section className="mx-auto max-w-xl border-t border-slate-300 py-8">
          <h2 className="text-xl font-medium text-slate-900">Stylování panoramat</h2>
          <p className="mt-1 text-sm text-slate-500">Nastavení vzhledu všech panoramat zákazníka.</p>
          <form
            action=""
            className="mt-6 space-y-6"
            onSubmit={(e) => {
              e.preventDefault();
              editCustomizationMutation.mutate({
                mainHotspotBackground,
                mainHotspotForeground,
                infoHotspotBackground,
                infoHotspotForeground,
                extendedInfoHotspotBackground,
                extendedInfoHotspotForeground,
                iconEdit,
                icon,
              });
            }}
          >
            <input type="submit" disabled className="hidden" value="submit" />
            <div className="relative">
              <Label htmlFor="panoramaPhoto">Ikonka</Label>
              <div
                className={classNames(
                  'relative mt-2 rounded-md border-2  px-6 pb-6 pt-5 transition duration-300 hover:border-blue-500',
                  iconPreviewUrl ? 'border-transparent' : 'border-dashed border-slate-300',
                )}
                style={{ backgroundColor: iconPreviewUrl ? mainHotspotBackground : '#fff' }}
              >
                <input
                  type="file"
                  accept="image/png"
                  onChange={(e) => onIconChange(e)}
                  className="absolute inset-0 h-full w-full cursor-pointer appearance-none opacity-0"
                />

                {iconPreviewUrl ? (
                  <>
                    <img
                      src={iconPreviewUrl}
                      onError={onIconLoadError}
                      height={76}
                      className="mx-auto h-[76px]"
                      alt=""
                    />
                  </>
                ) : (
                  <>
                    <svg
                      className="mx-auto mb-2 h-12 w-12 text-slate-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    <div className="text-center text-sm text-slate-700">Nahrajte fotku ikonky (.png)</div>
                  </>
                )}
              </div>
              <div className="absolute bottom-2 right-2">
                <Button variant="gray" size="smallSquare" icon="trash" onClick={onIconDelete} />
              </div>
            </div>
            <fieldset className="space-y-4">
              <legend className="text-xs font-medium uppercase text-slate-400">Hlavní hotspot</legend>
              <div>
                <Label htmlFor="mainHotspotBackground">Pozadí hlavního hotspotu</Label>
                <ColorInput
                  id="mainHotspotBackground"
                  value={mainHotspotBackground}
                  onChange={setMainHotspotBackground}
                />
              </div>
              <div>
                <Label htmlFor="mainHotspotForeground">Popředí hlavního hotspotu</Label>
                <ColorInput
                  id="mainHotspotForeground"
                  value={mainHotspotForeground}
                  onChange={setMainHotspotForeground}
                />
              </div>
            </fieldset>
            <fieldset className="space-y-4">
              <legend className="text-xs font-medium uppercase text-slate-400">Info hotspot</legend>
              <div>
                <Label htmlFor="infoHotspotBackground">Pozadí info hotspotu</Label>
                <ColorInput
                  id="infoHotspotBackground"
                  value={infoHotspotBackground}
                  onChange={setInfoHotspotBackground}
                />
              </div>
              <div>
                <Label htmlFor="infoHotspotForeground">Popředí info hotspotu</Label>
                <ColorInput
                  id="infoHotspotForeground"
                  value={infoHotspotForeground}
                  onChange={setInfoHotspotForeground}
                />
              </div>
            </fieldset>
            <fieldset className="space-y-4">
              <legend className="text-xs font-medium uppercase text-slate-400">Rozšířený info hotspot</legend>
              <div>
                <Label htmlFor="extendedInfoHotspotBackground">Pozadí rožsířeného info hotspotu</Label>
                <ColorInput
                  id="extendedInfoHotspotBackground"
                  value={extendedInfoHotspotBackground}
                  onChange={setExtendedInfoHotspotBackground}
                />
              </div>
              <div>
                <Label htmlFor="extendedInfoHotspotForeground">Popředí rožsířeného info hotspotu</Label>
                <ColorInput
                  id="extendedInfoHotspotForeground"
                  value={extendedInfoHotspotForeground}
                  onChange={setExtendedInfoHotspotForeground}
                />
              </div>
            </fieldset>
            <div className="flex justify-end">
              <Button icon="check" variant="blue" type="submit" isLoading={editMutation.isLoading}>
                Uložit
              </Button>
            </div>
          </form>
        </section>
        <Modal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          title="Opravdu si přejete odstranit zákazníka?"
          body={
            <div>
              <p className="text-gray-600">
                Opravdu si přejete odstranit zákaznika{' '}
                <strong className="font-semibold text-gray-800">{firstnameSurname}</strong> ? <br />
                Tato akce nelze vrátit zpět !
              </p>
            </div>
          }
          footer={
            <div className="flex justify-end gap-4">
              <Button icon="times" variant="gray" onClick={() => setIsDeleteModalOpen(false)}>
                Zrušit
              </Button>
              <Button
                icon="trash"
                variant="red"
                onClick={() => deleteMutation.mutate(undefined)}
                isLoading={editMutation.isLoading}
              >
                Potvrdit
              </Button>
            </div>
          }
        />
      </main>
    </>
  );
};
