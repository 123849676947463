import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { HotspotLink } from '../../redux/reducers/panorama.edit';
import { classNames } from '../../utils';
import { Gallery } from '../molecules';

type Props = {
  name: string;
  description: string;
  thumbnailUrl: string | undefined;
  matterportLink?: string;
  youtubeLink?: string;
  links: HotspotLink[];
  galleryImages: {
    url: string;
    thumbnailUrl: string;
  }[];
  style: {
    iconUrl: string;
    background: string;
    foreground: string;
  };
};

export const MainHotspot = ({
  name,
  description,
  matterportLink,
  youtubeLink,
  links,
  thumbnailUrl,
  galleryImages,
  style,
}: Props) => {
  const [isMatterportOpen, setIsMatterportOpen] = useState(false);
  const [isYoutubeVideoOpen, setIsYoutubeVideoOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [thumbnailStamp, setThumbnailStamp] = useState(0);
  const [thumbnailPresent, setThumbnailPresent] = useState(false);

  useEffect(() => setThumbnailStamp(new Date().getTime()), [thumbnailUrl]);

  return (
    <div className="absolute -bottom-[16px] z-[80] -ml-[16px] -mt-[16px] flex flex-col-reverse items-center justify-center">
      {/* Hotspot point */}
      <div
        className="h-[24px] w-[24px] rounded-full shadow"
        style={{ backgroundColor: style?.background ?? 'rgb(0 151 216)' }}
      />

      {/* Hotspot line */}
      <div className="h-[196px] w-[3px]" style={{ backgroundColor: style.background }} />
      <div className="absolute top-[56px] flex flex-col gap-1.5 pt-1.5">
        {galleryImages.length > 0 && (
          <button
            className="h-[32px] w-[32px] rounded-lg"
            style={{
              backgroundColor: style.background,
              color: style.foreground,
            }}
            onClick={() => setIsGalleryOpen(true)}
            title="Zobrazit galerii"
          >
            <FontAwesomeIcon icon="camera" />
          </button>
        )}
        {youtubeLink && (
          <button
            className="h-[32px] w-[32px]  rounded-lg"
            style={{
              backgroundColor: style.background,
              color: style.foreground,
            }}
            onClick={() => setIsYoutubeVideoOpen(true)}
            title="Zobrazit video"
          >
            <FontAwesomeIcon icon="video" />
          </button>
        )}
        {matterportLink && (
          <button
            className="h-[32px] w-[32px]  rounded-lg"
            style={{
              backgroundColor: style.background,
              color: style.foreground,
            }}
            onClick={() => setIsMatterportOpen(true)}
            title="Zobrazit matterport sken"
          >
            <FontAwesomeIcon icon="boxes" />
          </button>
        )}
      </div>

      {/* Main button */}
      <Popover as="div" className="relative">
        <Popover.Button
          className="rounded-full border-2 p-0.5 "
          title={name}
          style={{
            borderColor: style.background,
          }}
        >
          <span
            className="flex h-12 w-12 items-center justify-center rounded-full text-2xl"
            style={{
              backgroundColor: style.background,
              color: style.foreground,
            }}
          >
            <img src={style.iconUrl} className="w-6" alt="" />
          </span>
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 -translate-x-1/4 scale-90"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 -translate-x-1/4 scale-90"
        >
          <Popover.Panel className="absolute left-[calc(100%+12px)] top-0 w-[250px] overflow-hidden rounded-md shadow-xl">
            <div
              className="px-4 py-2 text-center font-medium"
              style={{
                backgroundColor: style.background,
                color: style.foreground,
              }}
            >
              <h3>{name}</h3>
            </div>
            <button
              className="group relative block bg-black"
              onClick={() => setIsGalleryOpen(true)}
              title="Zobrazit galerii"
            >
              <img
                width={250}
                className="transition duration-300 group-hover:opacity-50"
                src={`${thumbnailUrl}?${thumbnailStamp}`}
                title="Zobrazit galerii"
                alt=""
                onLoad={() => setThumbnailPresent(true)}
              />
              {thumbnailPresent && (
                <span className="absolute inset-0 flex items-center justify-center font-semibold text-slate-50 opacity-0 transition duration-300 group-hover:opacity-100">
                  Zobrazit galerii
                </span>
              )}
            </button>
            <div className="bg-slate-900/90 px-4 py-4 ">
              <div className="w-full whitespace-pre-wrap text-sm leading-relaxed text-slate-200">
                <div className="rich-output" dangerouslySetInnerHTML={{ __html: description }}></div>
              </div>
              {links.length > 0 && (
                <div className="-mx-1 mt-4 space-y-2">
                  {links.map((link) => (
                    <CustomLink
                      href={link.href}
                      target="_blank"
                      rel="noopener noreferrer external"
                      className={classNames(
                        'relative flex items-center justify-center gap-4 border-2 transition hover:shadow-lg focus:outline-none',
                        'rounded-md px-4 py-2 text-xs font-medium tracking-wide',
                      )}
                      background={style.background}
                      foreground={style.foreground}
                    >
                      {link.label}
                    </CustomLink>
                  ))}
                </div>
              )}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      {/* Gallery */}
      <Gallery
        isOpen={isGalleryOpen}
        title={name}
        onClose={() => setIsGalleryOpen(false)}
        images={galleryImages.map((image, i) => ({ ...image, alt: `${name}-${i + 1}` }))}
      />

      {/* Matterport */}
      <Transition show={isMatterportOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[5000]" onClose={() => setIsMatterportOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl border-2 border-blue-500 shadow-xl transition-all">
                  <iframe src={matterportLink} title="matterport" className="aspect-[3/2] w-full" />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Youtube */}
      <Transition show={isYoutubeVideoOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[5000]" onClose={() => setIsYoutubeVideoOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl shadow-xl transition-all">
                  <iframe src={youtubeLink} title="youtube" className="aspect-[3/2] w-full" />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

const CustomLink = styled.a<{ background: string; foreground: string }>`
  background: ${(props) => props.background};
  color: ${(props) => props.foreground};
  border-color: rgb(15 23 42);
  &:hover {
    border-color: ${(props) => [props.foreground]} !important;
  }
`;
